<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { ModalTypeSummary } from '~/types/wishlist'

const { setTemporaryTheme, pageTheme } = useHeaderNavigation()
const { fetchWishlists, fetched, wishlists } = useMultipleWishlist()
const { summaryOpen, setSummaryOpen } = useMultipleWishlistSummary()
const { setLock } = useLockBody()
const { isNewHeader } = useHeaderV2()

const modalStep = ref<ModalTypeSummary>()
const target = ref<HTMLDivElement | null>(null)
const inquiryAdditionalStep = ref(false)

onClickOutside(
  target,
  () => {
    setSummaryOpen(false)
    inquiryAdditionalStep.value = false
  },
  { ignore: ['button#header-wishlist'] },
)

const changeStep = (step: ModalTypeSummary) => {
  modalStep.value = step
}

// if multiple tabs open need to sync wishlists
watch(summaryOpen, async (value) => {
  setTemporaryTheme(value ? 'white' : pageTheme.value)
  setLock(value)
  if (value) await fetchWishlists()
})
</script>

<template>
  <TransitionGroup name="fade">
    <div
      v-if="summaryOpen"
      :class="[
        'fixed inset-x-0 z-modal h-screen w-full bg-gray-700/50 backdrop-blur transition duration-300 ease-in',
        isNewHeader ? 'top-[85px]' : 'top-[74px]',
      ]"
    />
    <div
      v-if="summaryOpen"
      ref="target"
      class="header-summary-wishlist-wrapper fixed right-10 z-[1060] mt-1.5 w-[332px] rounded border border-gray-200 bg-white px-3.5 py-3"
    >
      <WishlistMultipleSummaryInquiry
        v-if="inquiryAdditionalStep"
        @inquiry-finished="inquiryAdditionalStep = false"
      />
      <div v-show="!inquiryAdditionalStep">
        <WishlistMultipleSummaryList
          v-if="fetched && wishlists.length"
          @open-modal="changeStep"
          @inquiry-sended="inquiryAdditionalStep = true"
        />
        <WishlistMultipleSummaryEmptyList v-else />
      </div>
    </div>
  </TransitionGroup>
  <WishlistMultipleSummaryModal :step="modalStep" @change-step="changeStep" />
</template>

<style scoped>
.header-summary-wishlist-wrapper {
  top: var(--header-height);
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-300;
}

.fade-enter,
.fade-leave-to {
  @apply opacity-0;
}
</style>
