import { adjustArrays } from '@/utils/array'
import { algoliaSearch } from '@/utils/algolia'

import type { HouseSlug, Locale } from 'lc-services/types'

export type SearchInputState = {
  allDestinations: {
    child_ids: number[]
    clusterized_name: HouseSlug
    first_photo_url: string
    houses_count: number
    id: number
    is_country: boolean
    name: HouseSlug
    parent_id: number | null
  }[]
  breadcrumbsDestinationsIds: number[]
  data: {
    location: {
      id?: string
      name?: string
      slug?: HouseSlug
      type?: 'destination' | 'house'
    }
    startDate: Date | null
    endDate: Date | null
    guest: number
  }
  meta: {
    searchQuery: string
    searchQueryLast: string
  }
  submittedSearch: boolean
  destinations: { id: string; name: string; first_photo_url: string | null }[]
  houses: {
    id: string
    name: string
    slug?: HouseSlug
    first_photo_url: string | null
  }[]
  topDestinations: { id: string; name: string }[]
}

export const useSearchInput = () => {
  const { $httpCookies, $lcServicesSearch } = useNuxtApp()
  const { algoliaIds } = useAuth()
  const config = useRuntimeConfig()
  const { locale, t } = useI18n<unknown, Locale>()
  const { searchController, searchPresenter } = $lcServicesSearch
  const { trackEvent } = useTracking()
  const localePath = useLocalePath()
  const route = useRoute()

  const PLACEHOLDER_DESTI =
    'production/assets/images/search/placeholder-desti.jpg'

  const searchInputState = useState<SearchInputState>('search-input', () => ({
    allDestinations: [],
    breadcrumbsDestinationsIds: [],
    data: {
      location: {},
      startDate: null,
      endDate: null,
      guest: 0,
    },
    destinations: [],
    houses: [],
    meta: {
      searchQuery: '',
      searchQueryLast: '',
    },
    submittedSearch: false,
    topDestinations: [],
  }))

  const data = computed(() => searchInputState.value.data)
  const allDestinations = computed(() => searchInputState.value.allDestinations)
  const breadcrumbsDestinationsIds = computed(
    () => searchInputState.value.breadcrumbsDestinationsIds,
  )
  const destinations = computed(() => searchInputState.value.destinations)
  const houses = computed(() => searchInputState.value.houses)
  const meta = computed(() => searchInputState.value.meta)
  const submittedSearch = computed(() => searchInputState.value.submittedSearch)
  const topDestinations = computed(() => searchInputState.value.topDestinations)

  const routeName = computed(() => route.name?.toString().slice(0, -5) || '')
  const isLp = computed(() => route.meta?.destinationPage?.isLp || false)
  const pageReloadSearch = computed(() => {
    if (['search'].includes(routeName.value)) return true

    return !isLp.value && ['destination-name'].includes(routeName.value)
  })

  const optionsSearchEmpty = computed(
    () =>
      [
        {
          title: t('search.topDestinations'),
          key: 'destination',
          icon: 'pinAlt',
          items: topDestinations.value,
        },
      ] as const,
  )

  const optionsSearch = computed(() => {
    const [newDestinations, newHouses] = adjustArrays(
      { minPerArray: 2, maxItems: 6 },
      destinations.value,
      houses.value,
    )

    return [
      ...(newDestinations.length
        ? [
            {
              title: t('search.destinations'),
              key: 'destination',
              icon: 'pinAlt',
              items: newDestinations,
            },
          ]
        : []),
      ...(newHouses.length
        ? [
            {
              title: t('search.homes'),
              key: 'house',
              icon: 'home',
              items: newHouses,
            },
          ]
        : []),
    ] as Array<{
      title: string
      key: 'destination' | 'house'
      icon: 'home' | 'pinAlt'
      items: {
        first_photo_url: string | null
        id: string
        name: string
        slug?: HouseSlug
      }[]
    }>
  })

  const fetchAllDestinations = async () => {
    if (searchInputState.value.allDestinations.length) return

    const asyncIndex = algoliaSearch({
      indexName: 'public_destinations',
      config,
    })

    let batchHits = [] as SearchInputState['allDestinations']

    await asyncIndex.browseObjects({
      facetFilters: '',
      numericFilters: '',
      attributesToRetrieve: [
        'child_ids',
        'clusterized_name',
        'first_photo_url',
        'houses_count',
        'id',
        'is_country',
        'name',
        'parent_id',
      ],
      filters: 'NOT houses_count=0',
      batch: (batch) => {
        batchHits = batchHits.concat(batch)
      },
    })

    searchInputState.value.allDestinations = batchHits
  }

  const fetchTopDestination = async () => {
    if (searchInputState.value.topDestinations.length) return

    await searchController.getTopDestinations(locale.value, algoliaIds.value)

    searchInputState.value.topDestinations =
      searchPresenter.vm.topDestinationsResult.map((d) => ({
        id: d.id,
        name: d.name,
      }))
  }

  const fetchDestinationsAndHouses = async (value: string) => {
    if (!value) resetDestinationsAndHouses()

    await searchController.searchHousesAndDestinations(
      value,
      locale.value,
      'client',
      algoliaIds.value,
    )

    searchInputState.value.destinations =
      searchPresenter.vm.destinationsResult.map((d) => ({
        first_photo_url: d.first_photo_url || null,
        id: d.id,
        name: d.name,
      }))

    searchInputState.value.houses = searchPresenter.vm.housesResult.map(
      (h) => ({
        first_photo_url: h.first_photo_url || null,
        id: h.id,
        name: h.name,
        slug: h.slug,
      }),
    )
  }

  const goToHouse = () => {
    trackingGoTo('house')

    navigateTo(
      localePath({
        name: 'house-slug',
        params: { slug: data.value.location?.slug?.[locale.value] },
      }),
    )
  }

  const goToSearch = () => {
    trackingGoTo('destination')

    $httpCookies.set('search_params', null)

    navigateTo(
      localePath({
        name: 'search',
        query: {
          ...(data.value.location.id
            ? { destinationId: data.value.location.id }
            : {}),
          ...(data.value.guest
            ? { numericFilters: `capacity >= ${data.value.guest}` }
            : {}),
          ...(data.value.startDate && data.value.endDate
            ? {
                startDate: $dayjs(data.value.startDate).format('YYYY-MM-DD'),
                endDate: $dayjs(data.value.endDate).format('YYYY-MM-DD'),
              }
            : {}),
        },
      }),
    )
  }

  const trackingGoTo = (type: 'destination' | 'house') => {
    const searchQuery = meta.value.searchQueryLast
    const suggestion = searchQuery.length
      ? type === 'destination'
        ? 'location'
        : 'house'
      : 'default'
    const pageType = route.meta?.gtmAdditionalEventData?.page_type || 'other'

    const filterAppliedName = [
      data.value.location.id && 'desti_id',
      data.value.startDate && data.value.endDate && 'dates_selected',
      data.value.guest && 'capacity',
    ].filter(Boolean)

    trackEvent({
      event: 'search_started',
      filter_applied_name: filterAppliedName,
      source: pageType,
      page_type: pageType,
      suggestion,
      ...(searchQuery.length
        ? { search_query: searchQuery, is_autocomplete: Boolean(searchQuery) }
        : {}),
    })
  }

  const trackingInputClick = (field: 'destination' | 'calendar' | 'guests') => {
    const pageType = route.meta?.gtmAdditionalEventData?.page_type || 'other'

    trackEvent({
      event: 'search_input_clicked',
      field,
      page_type: pageType,
      source: pageType,
    })
  }

  const keepLastSearchQuery = () => {
    setMeta('searchQueryLast', meta.value.searchQuery)
    setMeta('searchQuery', '')
  }

  const resetDestinationsAndHouses = () => {
    searchInputState.value.destinations = []
    searchInputState.value.houses = []
  }

  const setData = <K extends keyof SearchInputState['data']>(
    key: K,
    value: SearchInputState['data'][K],
  ) => {
    searchInputState.value.data[key] = value
  }

  const setMeta = <K extends keyof SearchInputState['meta']>(
    key: K,
    value: SearchInputState['meta'][K],
  ) => {
    searchInputState.value.meta[key] = value
  }

  const setBreadcrumbsDestinationsIds = (ids: number[]) => {
    searchInputState.value.breadcrumbsDestinationsIds = ids
  }

  const resetData = () => {
    searchInputState.value.data = {
      location: {},
      startDate: null,
      endDate: null,
      guest: 0,
    }
    searchInputState.value.meta = {
      searchQuery: '',
      searchQueryLast: '',
    }
  }

  const setSubmittedSearch = (value: boolean) => {
    searchInputState.value.submittedSearch = value
  }

  return {
    allDestinations,
    breadcrumbsDestinationsIds,
    data,
    destinations,
    fetchAllDestinations,
    fetchDestinationsAndHouses,
    fetchTopDestination,
    goToHouse,
    goToSearch,
    houses,
    keepLastSearchQuery,
    meta,
    optionsSearch,
    optionsSearchEmpty,
    pageReloadSearch,
    PLACEHOLDER_DESTI,
    resetData,
    resetDestinationsAndHouses,
    setBreadcrumbsDestinationsIds,
    setData,
    setMeta,
    setSubmittedSearch,
    submittedSearch,
    topDestinations,
    trackingGoTo,
    trackingInputClick,
  }
}
