import { parsePhoneNumber, type CountryCode } from 'libphonenumber-js'
import type { SupportedCurrencies } from 'lc-services/types'
import type { UserInput } from '~/repository/entities/user'
import type { User } from 'lc-repository/dist/entities'

export const useUser = () => {
  const { $httpCookies, $i18n, $lcRepositories, $lcServicesUserProperties } =
    useNuxtApp()
  const currency = useCookie<SupportedCurrencies>('currency', {
    default: () => $httpCookies.get('currency'),
  })

  const USER_INFORMATIONS_DEFAULT = {
    address: null,
    bankAccountOwner: '',
    bic: '',
    birthdate: null,
    city: null,
    civility: null,
    clientGeneralRentalConditionSigned: false,
    companiesIds: [],
    confirmedAt: '',
    country: null,
    createdAt: '',
    devicesIds: [],
    email: '',
    esClientGeneralRentalConditionSignedAt: null,
    firstName: null,
    frClientGeneralRentalConditionSignedAt: null,
    hasAccessibleHouses: false,
    hasCompanies: false,
    hasHouses: false,
    housesIds: [],
    iban: '',
    id: '',
    identityDocumentExpirationDate: null,
    identityDocumentNumber: null,
    identityDocumentType: null,
    incompleteSignup: false,
    invitationAcceptedAt: null,
    invitationCreatedAt: null,
    invitationSentAt: null,
    isAdmin: false,
    lastName: null,
    locale: '',
    nationality: null,
    newsletterSubscribed: false,
    ownerGeneralRentalConditionSigned: false,
    partnershipAgency: false,
    partnershipAgencyName: '',
    phone: null,
    postalCode: null,
    ribFile: '',
    secondaryEmail: null,
    secondaryPhone: null,
    termsOfServiceSigned: false,
    termsOfServiceSignedAt: null,
    travelAgentGeneralRentalConditionSignedAt: null,
    updatedAt: '',
  }

  const userState = useState<User<{ serialized: true }>>('state-user', () => ({
    ...USER_INFORMATIONS_DEFAULT,
  }))

  const user = computed(() => userState.value)
  const hasMobileApp = computed(() => Boolean(user.value.devicesIds.length))
  const isOwner = computed(() => Boolean(user.value.housesIds.length))
  const userType = computed(() => {
    if (!user.value) return 'visitor'
    if (user.value.isAdmin) return 'admin'
    if (user.value.partnershipAgency) return 'agent'
    if (isOwner.value) return 'owner'
    if (user.value.id && user.value.incompleteSignup === false) return 'user'
    return 'visitor'
  })
  const formattedPhone = computed(() =>
    user.value.phone
      ? parsePhoneNumber(
          user.value.phone,
          (user.value.country ?? 'FR') as CountryCode,
        ).formatInternational()
      : '',
  )

  const fetchUserInformations = async () => {
    try {
      const res = await $lcRepositories.user.information.getUserInfo()
      if (res?.data) userState.value = res.data
    } catch {
      clearUser()
    }
  }

  const fetchUserPropertiesByLocation = async () => {
    if (!currency.value || !user.value.country) {
      const { userPropertiesController, userPropertiesPresenter } =
        $lcServicesUserProperties
      const storedRequest = () =>
        userPropertiesController
          .getUserPropertiesByLocation({
            locale: $i18n.locale.value,
          })
          .then(() => userPropertiesPresenter.vm.userProperties)

      const userProperties = await getStoredData(
        'userProperties',
        storedRequest,
      )
      if (!userProperties) return
      if (!currency.value) setCurrency(userProperties.currency)
      if (!user.value.country) {
        userState.value = {
          ...userState.value,
          country: userProperties.country,
        }
      }
    }
  }

  const modifyUserInformations = async (user: UserInput) => {
    const res = await $lcRepositories.user.information.modifyUserInfo(user)
    if (res?.data) userState.value = res.data
  }

  const clearUser = () => {
    userState.value = { ...USER_INFORMATIONS_DEFAULT }
  }

  const setCurrency = (newValue: SupportedCurrencies) => {
    currency.value = newValue || 'EUR'
  }

  return {
    clearUser,
    currency,
    fetchUserInformations,
    fetchUserPropertiesByLocation,
    formattedPhone,
    hasMobileApp,
    isOwner,
    modifyUserInformations,
    setCurrency,
    user,
    userType,
  }
}
