<script setup lang="ts">
import { useButtons } from '~/components/BaseForm/compose/use-buttons'

import groupFields from '~/components/Contact/form/groupField'
import { useURL } from '~/composables/useURL'
import type { MutationType } from '../BaseForm/types'
import type { SendMutationType } from '~/helpers/BaseForm/sendMutation'

const emits = defineEmits<{
  finished: []
}>()
defineSlots<{ title(): any }>()

const { $api, $httpCookies } = useNuxtApp()
const { getBaseURL } = useURL()
const { t } = useI18n()
const { trackEvent } = useTracking()
const { user, formattedPhone } = useUser()
const { headerNavigation } = useHeaderNavigation()

const previousUrl = computed(
  () => `${getBaseURL()}${headerNavigation.value.urlFrom}`,
)

const showValidation = ref(false)

const { btnPrimary } = useButtons()
btnPrimary.text = computed(() => t('inquiry.form.sendRequest'))
const initialData = reactive({
  firstname: user.value.firstName || '',
  lastname: user.value.lastName || '',
  email: user.value.email || '',
  phone: formattedPhone.value,
  kind: '',
  message: '',
})
const disabledPrimaryButton = computed(() => {
  if (
    initialData.email &&
    initialData.firstname &&
    initialData.kind &&
    initialData.lastname &&
    initialData.message &&
    initialData.phone
  ) {
    return false
  }

  return true
})

const groupFieldsForm = reactive(groupFields())
const configForm = reactive({
  fields: {
    message: {
      hidden: computed(() => initialData.kind === ''),
      attr: {
        placeholderTranslateKey: computed(() =>
          initialData.kind === 'inquiry' ? 'contact.placeHolderTextarea' : '',
        ),
        labelTranslateKey: computed(() =>
          initialData.kind === 'inquiry'
            ? 'field.messageContact'
            : 'field.message',
        ),
      },
      infoText: computed(() =>
        initialData.kind === 'inquiry' && initialData.message.length > 0
          ? t('contact.placeHolderTextarea')
          : '',
      ),
    },
  },
})

const createRequest = (data: typeof initialData) =>
  $api.v3.contacts.create(data)

const mutation: MutationType<typeof initialData> = (data) => ({
  request: createRequest,
  input: {
    contact: {
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      phone: data.phone,
      last_url_visited: previousUrl.value,
      kind: data.kind,
      message: data.message,
    },
    hubspotutk: $httpCookies?.get('hubspotutk'),
  },
})
const onSubmit: () => SendMutationType['onSubmit'] = () => ({
  success: (_, __, resetForm) => {
    showValidation.value = true

    trackEvent({
      event: 'form_sent_contact',
      hash: {
        email: initialData.email,
        phone: initialData.phone,
      },
    })

    setTimeout(() => {
      resetForm()
    }, 1000)
  },
  error: (err) => {
    console.warn(err)
  },
})

const onFinish = () => {
  showValidation.value = false
  emits('finished')
}
</script>

<template>
  <div class="contact-form">
    <base-success-form v-model="showValidation" @on-finish="onFinish">
      <slot name="title" />

      <base-form
        ref="contactForm"
        :active-modal-errors="false"
        :active-modal-save-or-quit="false"
        :button-primary="btnPrimary"
        :config-form="configForm"
        :disabled-primary-button="disabledPrimaryButton"
        :group-fields="groupFieldsForm"
        :initial-data="initialData"
        :mutation="mutation"
        :on-submit="onSubmit"
        container-button-class="mt-12 flex-wrap"
        id-form="contact-form"
        wrapper-button-class="flex flex-col-reverse"
      />
    </base-success-form>
  </div>
</template>

<style>
.contact-form {
  @apply relative justify-center;
}

.contact-form__form {
  @apply opacity-100;
  transition: opacity 0.3s ease-in 0.5s;
}
.contact-form__form.is-hidden {
  @apply opacity-0;
  transition: opacity 0.3s ease-in;
}

.contact-form__validation-row {
  @apply absolute top-0 left-0 w-full h-full;
}
.contact-form__validation-row.is-visible {
  @apply z-10;
}

.contact-form__validation {
  transition:
    opacity 0.3s ease-in,
    transform 0.5s ease-in;
  @apply w-full absolute text-center px-2 cursor-default opacity-0 translate-y-full top-1/2;
}

@screen md {
  .contact-form__validation {
    max-width: 600px;
    transform: translate(-50%, 100%);
    @apply left-1/2;
  }
}
.contact-form__validation .contact-form__validation-button {
  @apply cursor-default;
}
.contact-form__validation-row.is-visible .contact-form__validation {
  @apply opacity-100;
  transform: translateY(-50%);
  transition:
    opacity 0.3s ease 0.3s,
    transform 0.5s 0.3s ease;
}

@screen md {
  .contact-form__validation-row.is-visible .contact-form__validation {
    transform: translate(-50%, -50%);
  }
}
.contact-form__validation-row.is-visible
  .contact-form__validation
  .contact-form__validation-button {
  @apply cursor-pointer;
}

.contact-form__validation-text {
  margin: 0 0 2rem 0;
}

.contact-form__validation-button {
  @apply w-full;
}

@screen md {
  .contact-form__validation-button {
    @apply w-auto;
    min-width: 200px;
  }
}

.contact-form__icon {
  margin: 0 1rem 0 0;
  @apply font-medium align-baseline;
}

.contact-form .base-field-info {
  @apply relative ml-0 mt-1;
}
.contact-form .base-field-info p {
  @apply absolute;
}

.contact-form .input-message textarea:focus::placeholder {
  @apply text-transparent;
}

.contact-form .input-message .base-field-info {
  @apply opacity-0 transition-all;
}

.contact-form .input-message .base-field-info.input-has-focus,
.contact-form .input-message .base-field-info.input-has-value {
  @apply opacity-100 transition-all;
}
</style>
