<script setup lang="ts">
const modalCount = useModalCount()

type BaseModalSize = 'xs' | 's' | 'm' | 'l'

const props = withDefaults(
  defineProps<{
    activeEscMode?: boolean
    hasBackButton?: boolean
    hasCloseButton?: boolean
    link?: string
    linkDisabled?: boolean
    minHeight?: boolean
    mobileFullHeight?: boolean
    modelValue: boolean
    noMargin?: boolean
    noRounded?: boolean
    primaryButton?: string
    primaryButtonDisabled?: boolean
    primaryButtonLoading?: boolean
    requiredReading?: boolean
    secondaryButton?: string
    secondaryButtonDisabled?: boolean
    secondaryButtonLoading?: boolean
    size?: BaseModalSize
  }>(),
  {
    activeEscMode: false,
    hasBackButton: false,
    hasCloseButton: true,
    link: '',
    linkDisabled: false,
    minHeight: false,
    mobileFullHeight: false,
    noMargin: false,
    noRounded: false,
    primaryButton: '',
    primaryButtonDisabled: false,
    primaryButtonLoading: false,
    requiredReading: false,
    secondaryButton: '',
    secondaryButtonDisabled: false,
    secondaryButtonLoading: false,
    size: 's',
  },
)
const emits = defineEmits<{
  'primary-button': []
  'secondary-button': []
  'update:model-value': [value: boolean]
  back: []
  close: []
  link: []
}>()

defineSlots<{
  header(p: { closeModal: () => void }): any
  content(): any
  footer(): any
}>()

const sizeClass = computed(() => {
  const classes = {
    xs: 'lg:w-[400px]',
    s: 'lg:w-[608px]',
    m: 'lg:w-[863px]',
    l: 'lg:w-[1024px]',
  }

  return classes[props.size as BaseModalSize]
})

const displayFooter = computed(
  () => props.link || props.primaryButton || props.secondaryButton,
)
const baseModalContent = ref<HTMLDivElement | null>(null)

const closeModal = () => {
  if (props.hasCloseButton) {
    emits('update:model-value', false)
    emits('close')
  }
}

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      modalCount.value++
    } else {
      modalCount.value--
    }
  },
)

const escFunction = (e: KeyboardEvent) => {
  const escTouch = 'Escape'
  if (e.key === escTouch) {
    closeModal()
  }
}

const scrollTo = () => {
  const element = baseModalContent.value
  const height = element?.scrollHeight

  if (element) {
    element.scrollTo({
      top: height,
      left: 0,
      behavior: 'smooth',
    })
  }
}

const handleScroll = (e: Event) => {
  if (props.requiredReading && e.target) {
    const target = e.target as HTMLDivElement
    const offsetTop = target.scrollHeight - target.offsetHeight
    const scrollTop = Math.round(target.scrollTop)

    if (Math.abs(offsetTop - scrollTop) < 10) {
      emits('link')
    }
  }
}

onMounted(() => {
  // Handle case when modal is open on page load
  if (props.modelValue) modalCount.value++

  if (props.activeEscMode) {
    document.addEventListener('keyup', escFunction, false)
  }
})

onBeforeUnmount(() => {
  if (props.activeEscMode) {
    document.removeEventListener('keyup', escFunction, false)
  }
})
</script>

<template>
  <div
    v-if="modelValue"
    class="fixed bottom-0 left-0 z-modal h-dvh w-full bg-[rgba(0,0,0,0.5)]"
    @click.self="closeModal"
  >
    <div
      :class="[
        sizeClass,
        { 'min-h-[calc(100vh-2rem)]': minHeight },
        { 'h-full': mobileFullHeight },
        { 'lg:rounded-lg': !noRounded },
        mobileFullHeight
          ? 'animate-[0.3s_baseModalFadeIn]'
          : 'animate-[0.3s_baseModalFadeInTop] lg:animate-[0.3s_baseModalFadeIn]',
        `
          absolute bottom-0 flex max-h-dvh w-full flex-col justify-between
          overflow-auto bg-white lg:bottom-auto lg:left-1/2 lg:top-1/2
          lg:h-auto lg:max-h-[calc(100vh-2rem)] lg:max-w-[calc(100vw-2rem)]
          lg:-translate-x-1/2 lg:-translate-y-1/2
        `,
      ]"
    >
      <div>
        <slot name="header" :close-modal="closeModal">
          <div
            v-if="hasBackButton || hasCloseButton"
            :class="[
              'sticky top-0 z-sticky flex items-center justify-between bg-white px-4 py-6 lg:translate-y-10 lg:bg-transparent lg:px-8 lg:py-0',
              {
                '!pt-8': !hasCloseButton,
              },
            ]"
          >
            <button
              v-if="hasBackButton"
              class="flex -translate-x-1.5 items-center text-md font-medium lg:hidden"
              type="button"
              @click="emits('back')"
            >
              <BaseIcon class="mr-2" name="chevronLeft" />
              {{ $t('action.back') }}
            </button>
            <button
              v-show="hasCloseButton"
              class="sticky left-full top-0 ml-auto rounded-full bg-white p-2 shadow-normal"
              data-cy="close-modal"
              type="button"
              @click="closeModal"
            >
              <BaseIcon name="close" />
            </button>
          </div>
        </slot>
        <div
          ref="baseModalContent"
          :class="[
            'relative',
            { 'mb-4 lg:mb-8': !noMargin && !displayFooter },
            { 'h-[550px] overflow-y-scroll': requiredReading },
            { 'pt-8': !hasCloseButton && !hasBackButton },
            { 'mx-4 lg:mx-8': !noMargin },
          ]"
          @scroll="handleScroll"
        >
          <slot name="content" />
        </div>
      </div>
      <div
        v-if="displayFooter"
        class="modal__footer sticky bottom-0 flex items-center justify-between border-t border-gray-200 bg-white p-4 lg:px-8 lg:py-6"
      >
        <slot name="footer">
          <div>
            <BaseButton
              v-if="link"
              :disabled="linkDisabled"
              class="text-md !font-semibold !underline"
              variant="link"
              color="black"
              @click="emits('link')"
            >
              {{ link }}
            </BaseButton>
            <BaseButton
              v-if="requiredReading"
              class="!py-3"
              color="secondary"
              variant="outline"
              @click="scrollTo"
            >
              <BaseIcon name="navArrowDown" />
            </BaseButton>
          </div>

          <div class="ml-auto">
            <BaseButton
              v-if="secondaryButton"
              :disabled="secondaryButtonDisabled"
              class="mr-4 !px-4 !py-3 !font-semibold"
              color="secondary"
              :loader="secondaryButtonLoading"
              variant="outline"
              @click="emits('secondary-button')"
            >
              {{ secondaryButton }}
            </BaseButton>
            <BaseButton
              v-if="primaryButton"
              :disabled="primaryButtonDisabled"
              class="!px-4 !py-3"
              color="secondary"
              :loader="primaryButtonLoading"
              @click="emits('primary-button')"
            >
              {{ primaryButton }}
            </BaseButton>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<style>
@keyframes baseModalFadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes baseModalFadeInTop {
  from {
    opacity: 0%;
    bottom: -100%;
  }
  to {
    opacity: 100%;
  }
}
</style>
