<script setup lang="ts">
const { locale } = useI18n()
const { getHref } = useMegaMenuDestinations()
const { setMenuOpen } = useHeaderV2()

const selectedDestination = ref<number>()

const setSelectedDestination = (destinationId?: number) => {
  selectedDestination.value = destinationId
}

const closeMenu = () => {
  setMenuOpen(false)
  setSelectedDestination()
}

defineExpose({ selectedDestination, setSelectedDestination })

const { data: getDestinationsData } = await useFetch(
  `/api/${locale.value}/header-destinations`,
  {
    transform: (res) => {
      return res.map((node) => {
        const totalChildren = node.children.reduce((acc, child) => {
          return (
            acc +
            1 +
            (child.children
              ? child.children.reduce(
                  (acc, grandchild) =>
                    acc +
                    1 +
                    (grandchild.children ? grandchild.children.length : 0),
                  0,
                )
              : 0)
          )
        }, 0)

        return {
          ...node,
          totalChildren,
        }
      })
    },
  },
)
</script>

<template>
  <div class="h-full justify-between overflow-y-auto px-6 pb-6">
    <div
      v-if="!selectedDestination"
      class="font-monospace mb-7 text-xl leading-6 text-gray-700"
    >
      {{ $t('headerSearch.destinations') }}
    </div>

    <ul
      :class="[
        'mb-0 ',
        { 'grid grid-cols-2 gap-x-2 gap-y-4': !selectedDestination },
      ]"
    >
      <li
        v-for="destination in getDestinationsData"
        :key="`destination-${destination.id}`"
      >
        <NuxtLink
          v-if="!destination.children.length"
          :class="['!no-underline', { hidden: selectedDestination }]"
          :to="getHref(destination.link)"
          @click.prevent="closeMenu"
        >
          <div class="relative mb-2 overflow-hidden p-[50%_0_0]">
            <BaseNuxtImg
              :alt="destination.name"
              :src="destination.first_photo_url"
              aspect-ratio="1/2"
              class="absolute inset-0 rounded"
              height="85"
              width="170"
            />
          </div>
          <span class="block text-md font-medium text-gray-700">
            {{ destination.name }}
          </span>
        </NuxtLink>
        <template v-else>
          <button
            :class="['w-full text-left', { hidden: selectedDestination }]"
            type="button"
            @click="setSelectedDestination(destination.id)"
          >
            <div class="relative mb-2 overflow-hidden p-[50%_0_0]">
              <BaseNuxtImg
                :alt="destination.name"
                :src="destination.first_photo_url"
                aspect-ratio="1/2"
                class="absolute inset-0 rounded"
                height="85"
                width="170"
              />
            </div>
            <span class="block text-md font-medium text-gray-700">
              {{ destination.name }}
            </span>
            <span
              v-if="destination.children.length"
              class="block text-xs text-gray-500"
            >
              {{
                $t('header.destinationCount', {
                  count: destination.totalChildren,
                })
              }}
            </span>
          </button>

          <ul
            :class="[
              'mb-0',
              { hidden: destination.id !== selectedDestination },
            ]"
          >
            <div class="font-monospace mb-6 text-3xl lg:mb-11">
              {{ destination.name }}
            </div>

            <NuxtLink
              class="mb-9 block font-bold leading-6 text-gray-700"
              :to="getHref(destination.link)"
              @click.prevent="closeMenu"
            >
              {{
                $t('headerDestination.seeAllPropertiesWithName', {
                  desti: destination.name?.trim(),
                })
              }}
            </NuxtLink>

            <li
              v-for="subRegion in destination.children"
              :key="`subRegion-${subRegion.id}`"
              :class="{ 'mb-4': subRegion.style === 'title' }"
            >
              <NuxtLink
                :class="[
                  'inline-block leading-6 text-gray-700',
                  {
                    'font-bold':
                      subRegion.style === 'bold' || subRegion.style === 'title',
                    'mb-7': subRegion.style === 'title',
                    'py-1.5': subRegion.style !== 'title',
                  },
                ]"
                :to="getHref(subRegion.link)"
                @click.prevent="closeMenu"
              >
                {{ subRegion.name }}
              </NuxtLink>

              <ul v-if="subRegion?.children?.length" class="mb-8">
                <li
                  v-for="region in subRegion.children"
                  :key="`region-${region.id}`"
                >
                  <NuxtLink
                    :class="[
                      'inline-block py-1.5 leading-6 text-gray-700	',
                      {
                        'font-bold': region.style === 'bold',
                      },
                    ]"
                    :to="getHref(region.link)"
                    @click.prevent="closeMenu"
                  >
                    {{ region.name }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>
