<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const { locale } = useI18n<unknown, Locale>()
const localePath = useLocalePath()
const { trackEvent, pageType } = useTracking()

const emits = defineEmits<{ selected: []; close: [] }>()

const {
  allDestinations,
  breadcrumbsDestinationsIds,
  setBreadcrumbsDestinationsIds,
  setData,
} = useSearchInput()

const firstLevel = computed(() =>
  [...allDestinations.value.filter((d) => d.is_country)].sort(
    (a, b) => b.houses_count - a.houses_count,
  ),
)

const handleClickCountry = (desti: {
  asChild: boolean
  clusterizedName: string
  id: number
  name: string
}) => {
  if (desti.asChild) {
    setBreadcrumbsDestinationsIds([
      ...breadcrumbsDestinationsIds.value,
      desti.id,
    ])
  } else {
    emits('selected')
  }

  setData('location', {
    id: desti.id.toString(),
    name: desti.clusterizedName,
    type: 'destination',
  })

  trackEvent({
    event: 'search_destination_click',
    destination_id: desti.id.toString(),
    destination_name: desti.name,
    page_type: pageType.value,
  })
}

const handleClickMap = () => {
  trackEvent({
    category: 'Search',
    event: 'map_open',
    page_type: pageType.value,
    search_query: '',
    source: 'Search input destination field CTA',
  })
  emits('close')
}
</script>

<template>
  <div>
    <div class="mb-4 text-left text-xs font-bold">
      {{ $t('search.ourDestinations') }}
    </div>
    <div
      class="mb-6 flex gap-x-2 overflow-x-auto md:mb-4 md:grid md:grid-cols-5 md:gap-y-4"
    >
      <template v-if="!firstLevel.length">
        <div
          v-for="index in Array.from({ length: 10 }, (_, i) => i)"
          :key="index"
        >
          <div class="animate-wave mb-2 h-[83px] w-[122px] rounded" />
          <div class="animate-wave mb-1 h-[22px] w-[50px]" />
          <div class="animate-wave h-[16px] w-[100px]" />
        </div>
      </template>
      <template v-else>
        <button
          v-for="destination in firstLevel"
          :key="destination.id"
          type="button"
          class="w-[128px] min-w-[128px] text-left md:w-auto md:min-w-full"
          @click.stop="
            handleClickCountry({
              asChild: Boolean(destination.child_ids.length),
              clusterizedName: destination.clusterized_name[locale],
              id: destination.id,
              name: destination.name[locale],
            })
          "
        >
          <div class="mb-2">
            <BaseNuxtImg
              :alt="destination.name[locale]"
              :src="destination.first_photo_url"
              class="rounded"
              height="83"
              width="128"
            />
          </div>
          <div class="text-md font-medium leading-5 text-gray-700">
            {{ destination.name[locale] }}
          </div>
          <div class="text-xs leading-4 text-gray-500">
            {{ $t('search.results', { count: destination.houses_count }) }}
          </div>
        </button>
      </template>
    </div>

    <div class="relative">
      <BaseNuxtImg
        alt="map"
        src="production/assets/images/search/map.jpg"
        class="rounded"
        height="112"
        width="662"
      />
      <BaseButton
        color="secondary"
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 !px-3 !py-2 !text-xs md:!text-base"
        :to="localePath({ name: 'search', query: { map: 'open' } })"
        @click.prevent="handleClickMap"
      >
        {{ $t('search.seeOnTheMap') }}
      </BaseButton>
    </div>
  </div>
</template>
