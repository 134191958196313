<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { TheHeaderV2MenuDestinations } from '#build/components'

const { setLock } = useLockBody()
const { menuOpen, setMenuOpen, menuActiveKey, setMenuNavigation } =
  useHeaderV2()

const target = ref<HTMLDivElement | null>(null)
const menuDestinations = ref<InstanceType<
  typeof TheHeaderV2MenuDestinations
> | null>(null)

onClickOutside(target, () => {
  setMenuOpen(false)
  if (menuDestinations.value?.selectedDestination) {
    menuDestinations.value.setSelectedDestination()
  }
})

watch(menuOpen, (value) => {
  setLock(value)
})

const back = () => {
  if (menuDestinations.value?.selectedDestination) {
    menuDestinations.value.setSelectedDestination()
  } else {
    setMenuNavigation('summary')
  }
}
</script>

<template>
  <div
    v-if="menuOpen"
    class="fixed inset-x-0 top-0 z-modal h-screen w-full bg-gray-700/50 backdrop-blur transition duration-300 ease-in"
  />
  <div
    ref="target"
    :class="[
      'fixed top-0 z-[1060] h-screen w-full overflow-hidden bg-white transition-all duration-300 ease-in lg:w-[395px]',
      {
        '-translate-x-full lg:translate-x-[-395px]': !menuOpen,
        'translate-x-0': menuOpen,
      },
    ]"
  >
    <div
      :class="[
        'mb-7 mt-4 flex px-6 lg:my-11',
        {
          'justify-end': menuActiveKey === 'summary',
          'justify-between': menuActiveKey !== 'summary',
        },
      ]"
    >
      <button
        v-if="menuActiveKey !== 'summary'"
        class="-ml-2 flex items-center"
        type="button"
        @click="back"
      >
        <BaseIcon class="mr-2" name="navArrowLeft" />
        <div class="text-md font-medium">
          {{ $t('action.back') }}
        </div>
      </button>
      <button type="button" class="-mr-1" @click="setMenuOpen(false)">
        <BaseIcon name="close" />
      </button>
    </div>

    <div class="h-[calc(100%-68px)] lg:h-[calc(100%-112px)]">
      <div class="h-full">
        <TheHeaderV2MenuSummary v-show="menuActiveKey === 'summary'" />
        <TheHeaderV2MenuDestinations
          v-show="menuActiveKey === 'destinations'"
          ref="menuDestinations"
        />
        <TheHeaderV2MenuInspirations
          v-show="menuActiveKey === 'inspirations'"
        />
        <TheHeaderV2MenuAboutUs v-show="menuActiveKey === 'aboutUs'" />
      </div>
    </div>
  </div>
</template>
