<script setup lang="ts">
import { useScrollTo } from '#imports'
import type { LocationQuery } from 'vue-router'
import type { InputSearchQuery } from '~/types/search/types'

const props = withDefaults(
  defineProps<{
    collections?: boolean
    exclus?: boolean
  }>(),
  {
    collections: false,
    exclus: false,
  },
)
const { isMobile } = useBreakpoint()
const localePath = useLocalePath()
const router = useRouter()
const { trackEvent } = useTracking()
const { isNewHeader } = useHeaderV2()
const { $experimentClient } = useNuxtApp()
const { isTreatment } = useExperiment('sticky-search')

const query = ref({} as InputSearchQuery)

const imageCover = computed(() =>
  isMobile.value
    ? 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2024/cover-winter-2024-mobile.webp'
    : 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2024/cover-winter-2024-desktop.webp',
)
const s3Url = computed(() =>
  isMobile.value
    ? 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2024/video-winter-2024-mobile.mp4'
    : 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2024/video-winter-2024-desktop-light.mp4',
)

const arrowScrollTo = computed(() => {
  let result = 'selection'

  if (props.collections) result = 'collections'
  if (props.exclus) result = 'exclus'

  return result
})

const goToSearch = (
  selectedOption: { type: string; label: string; value: string; slug: string },
  searchQuery: LocationQuery,
) => {
  if (selectedOption.type === 'location') {
    trackEvent({
      category: 'Homepage',
      event: 'search_started',
      source: 'homepage',
      suggestion: 'location',
      search_query: searchQuery,
      is_autocomplete: Boolean(searchQuery),
    })

    const path = localePath({
      name: 'search',
      query: { destinationId: selectedOption.value },
    })

    router.push(path)
  } else if (selectedOption.type === 'type-of-house') {
    trackEvent({
      category: 'Homepage',
      event: 'search_started',
      source: 'homepage',
      suggestion: 'house',
      search_query: searchQuery,
      is_autocomplete: Boolean(searchQuery),
    })

    const path = localePath({
      name: 'house-slug',
      params: { slug: selectedOption.slug },
    })

    router.push(path)
  }
}

const trackWhereToLeave = () => {
  trackEvent({
    category: 'General',
    event: 'typeform_homepage_click',
  })
}

const containerSearch = ref<HTMLDivElement | null>(null)
const fixed = ref(false)
const SIZE_TOP_FIXED = 24

const onScroll = () => {
  const position = containerSearch.value?.getBoundingClientRect()?.top || 0

  fixed.value = position - SIZE_TOP_FIXED < 0
}

onMounted(() => {
  $experimentClient?.exposure('sticky-search')
  if (isNewHeader.value) {
    document.addEventListener('scroll', onScroll)
  }
})

onUnmounted(() => {
  if (isNewHeader.value) {
    document.removeEventListener('scroll', onScroll)
  }
})
</script>

<template>
  <BaseVideoBg
    :class="[
      'flex items-center text-center',
      { 'mb-[200px] h-[458px] md:mb-0': isTreatment },
      { 'h-screen min-h-[568px]': !isTreatment },
    ]"
    :img="imageCover"
    :source="s3Url"
  >
    <div class="home-bg-video__overlay" />
    <BaseContainer :class="{ 'pt-[510px] md:pt-0': isTreatment }">
      <BaseRow class="justify-center">
        <BaseCol md="20" lg="14">
          <template v-if="isNewHeader">
            <h2
              class="mb-2 text-5xl leading-9 text-white md:text-[28px] md:leading-7"
              data-cy="homepage-title"
            >
              {{ $t('home.headline') }}
            </h2>
            <p
              class="mb-0 hidden text-md leading-5 text-white md:block md:text-base"
              data-cy="homepage-subtitle"
            >
              {{ $t('home.headline_subtitle') }}
            </p>

            <SearchInputV2MobileContainer v-if="isMobile" />
            <div
              v-else
              ref="containerSearch"
              class="my-4 flex h-[52px] justify-center"
            >
              <SearchInputV2Container
                :class="{ 'fixed top-4 z-modal': fixed }"
                large
                next-flow
              />
            </div>

            <BaseButton
              :to="localePath({ name: 'typeform' })"
              target="_blank"
              class="!text-gray-700 md:!text-white"
              variant="link"
              @mousedown="trackWhereToLeave"
            >
              {{ $t('home.where_to') }}
            </BaseButton>
          </template>
          <template v-else>
            <h2
              class="home-bg-video__title text-pre-line"
              data-cy="homepage-title"
            >
              {{ $t('home.headline') }}
            </h2>
            <p class="home-bg-video__subtitle" data-cy="homepage-subtitle">
              {{ $t('home.headline_subtitle') }}
            </p>

            <SearchInput
              :has-clear-button="false"
              :has-icon="false"
              :has-search-button="true"
              :model-value="query"
              class="search__input"
              location="homepage"
              @refine-by-query="goToSearch"
            />

            <BaseButton
              :to="localePath({ name: 'typeform' })"
              target="_blank"
              color="primary-light"
              variant="link"
              class="mt-1"
              @mousedown="trackWhereToLeave"
            >
              {{ $t('home.where_to') }}
            </BaseButton>
          </template>
        </BaseCol>
      </BaseRow>
    </BaseContainer>

    <BaseArrowScroll
      v-if="!isTreatment"
      class="home-bg-video__arrow-scroll"
      @click="useScrollTo({ id: arrowScrollTo })"
    />
  </BaseVideoBg>
</template>

<style>
.home-bg-video__overlay {
  @apply bg-gray-700 bg-opacity-40 w-full h-full absolute;
}

.home-bg-video__title {
  margin: 0 0 1rem 0;
  @apply text-7xl;
}

@screen md {
  .home-bg-video__title {
    @apply text-8xl;
  }
}

.home-bg-video__title,
.home-bg-video__subtitle {
  @apply text-white;
}

.home-bg-video__subtitle {
  margin: 0 0 1.5rem 0;
}

.home-bg-video__arrow-scroll {
  @apply hidden absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

@screen sm {
  .home-bg-video__arrow-scroll {
    @apply block;
  }
}

.homepage .search-input .multiselect-label-span {
  @apply text-left pl-4;
}
</style>
