<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const { isDesktop, isMobile } = useBreakpoint()
const { locale } = useI18n<unknown, Locale>()

const { href } = defineProps<{ href: string }>()

const isAction = computed(() => href.startsWith('javascript:'))
const isCollectionist = computed(() => href.includes('lecollectionist.com'))
const isExternal = computed(() => href.startsWith('http'))
const isPhone = computed(() => href.startsWith('tel:'))
const isPage = computed(
  () =>
    !isAction.value &&
    !isCollectionist.value &&
    !isExternal.value &&
    !isPhone.value,
)
const rel = computed(() =>
  ([] as string[])
    .concat(isAction.value || isExternal.value ? ['noopener'] : [])
    .concat(isCollectionist.value || isPage.value ? [] : ['nofollow'])
    .join(' '),
)
const to = computed(() => (isPage.value ? `/${locale.value}/${href}` : href))

defineSlots<{ default(): any }>()
</script>

<template>
  <NuxtLink
    class="flex items-center gap-2"
    :external="isExternal"
    :rel="rel"
    :target="isExternal && isDesktop ? '_blank' : '_self'"
    :to="to"
    v-bind="$attrs"
  >
    <div v-if="isPhone" class="flex items-center gap-2">
      <BaseIcon name="phone" :size="isMobile ? 1 : 1.25" />
      <span class="flex flex-wrap items-center gap-x-1">
        <slot />
        <span>{{ href.split('tel:')[1] }}</span>
      </span>
    </div>
    <slot v-else />
  </NuxtLink>
</template>
