<script setup lang="ts">
import { phoneByLanguage } from '~/helpers/getPhoneByLanguage'

import type { Locale } from 'lc-services/types'
import type { MenuKey } from '@/composables/Header/useHeaderV2'

const LANGUAGES = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },
] as const

const target = ref<HTMLDivElement | null>(null)
onClickOutside(target, () => {
  setMenuOpen(false)
})

const { currency, user } = useUser()
const { isMobile } = useBreakpoint()
const { locale, t } = useI18n<unknown, Locale>({ useScope: 'local' })
const { setMenuNavigation } = useHeaderV2()
const { setMenuOpen, setLanguageCurrencyOpen, setContactOpen } = useHeaderV2()
const localePath = useLocalePath()
const config = useRuntimeConfig()
const { trackEvent, pageType } = useTracking()
const { startTimeWeek, endTimeWeek, saturdayEndTimeWeek } =
  useCallCenterSchedule()

const currentLanguage = computed(
  () => LANGUAGES.find((l) => l.code === locale.value)?.name,
)

const links = computed(() => [
  {
    key: 'destinations' as const,
    name: t('menu.destinations'),
    tracking: 'destinations',
  },
  {
    key: 'inspirations' as const,
    name: t('menu.inspirations'),
    tracking: 'inspirations',
  },
  {
    key: 'aboutUs' as const,
    name: t('menu.aboutUs'),
    tracking: 'aboutUs',
  },
  {
    name: t('menu.conciergerie'),
    to: localePath('tailor'),
    tracking: 'conciergerie',
  },
  { name: t('menu.event'), to: localePath('events'), tracking: 'event' },
  {
    name: t('menu.collection'),
    to: localePath('owners'),
    tracking: 'collection',
  },
  {
    name: t('menu.realEstate'),
    to: localePath('realEstateListings'),
    tracking: 'realEstate',
  },
  {
    name: t('menu.travelPartners'),
    to: localePath('travelPartners'),
    tracking: 'travelPartners',
  },
])

const phoneNumber = computed(() =>
  phoneByLanguage({ config, language: user.value.country }),
)

const tracking = (tab: string) => {
  trackEvent({
    event: 'menu_burger_tab_click',
    page_type: pageType.value,
    menu_tab: tab,
  })
}

const handleContact = () => {
  setMenuOpen(false)
  if (isMobile.value) setContactOpen(true)

  trackEvent({
    event: 'form_contact_click',
    page_type: pageType.value,
  })
}

const handleLanguageAndCurrency = () => {
  setMenuOpen(false)
  setLanguageCurrencyOpen(true)
}

const handleRedirectPage = (tab: string) => {
  setMenuOpen(false)
  tracking(tab)
}

const handleSubMenu = (key: MenuKey, tab: string) => {
  setMenuNavigation(key)
  tracking(tab)
}
</script>

<template>
  <div class="flex h-full flex-col overflow-y-auto lg:justify-between">
    <div>
      <ul class="mb-5 px-6">
        <li v-for="link in links" :key="link.to" class="group">
          <NuxtLink
            v-if="link.to"
            :to="link.to"
            class="font-monospace block py-4 text-xl leading-6 text-gray-700 hover:text-secondary-400 hover:no-underline group-first:pt-0"
            @click.prevent="handleRedirectPage(link.tracking)"
          >
            {{ link.name }}
          </NuxtLink>
          <button
            v-else-if="link.key"
            type="button"
            class="font-monospace group flex w-full justify-between py-4 text-xl leading-6 text-gray-700 transition duration-200 hover:text-secondary-400 group-first:pt-0 lg:justify-normal"
            @click="handleSubMenu(link.key, link.tracking)"
          >
            <span>{{ link.name }}</span>
            <BaseIcon
              class="translate-x-0 opacity-0 transition-all duration-500 ease-in-out group-hover:translate-x-2 group-hover:opacity-100"
              name="arrowNarrowRight"
            />
          </button>
        </li>
      </ul>
    </div>

    <div class="p-6 lg:bg-beige">
      <div class="mb-4 flex items-center gap-4 whitespace-break-spaces">
        <BaseIcon class="flex-none" name="multiBubble" />
        <div>
          <div class="mb-0.5 pr-1 text-md font-medium leading-5">
            {{ t('footer.callUs') }}
            <a class="text-gray-700" :href="`tel:${phoneNumber}`">
              {{ phoneNumber }}
            </a>
          </div>

          <p class="mb-0 text-xs leading-4">
            {{
              t('footer.schedules', {
                startTimeWeek,
                endTimeWeek,
                saturdayEndTimeWeek,
              })
            }}
          </p>
        </div>
      </div>

      <button
        v-if="isMobile"
        class="flex items-center gap-4 text-md font-medium leading-6 text-gray-700 hover:text-secondary-400 hover:underline"
        type="button"
        @click.prevent="handleContact"
      >
        <BaseIcon class="flex-none" name="mail" />
        {{ t('footer.contactUs') }}
      </button>
      <NuxtLink
        v-else
        :to="localePath('contact')"
        class="flex items-center gap-4 text-md font-medium leading-6 text-gray-700 hover:text-secondary-400 hover:underline"
        @click.prevent="handleContact"
      >
        <BaseIcon class="flex-none" name="mail" />
        {{ t('footer.contactUs') }}
      </NuxtLink>
      <button
        class="mt-6 text-md underline hover:font-medium hover:text-secondary-400"
        type="button"
        @click="handleLanguageAndCurrency"
      >
        {{ currentLanguage }} -
        <span v-if="currency" class="capitalize">
          {{ currency.toLowerCase() }}
        </span>
      </button>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "fr": {
    "menu": {
      "destinations": "Destinations",
      "inspirations": "Inspirations",
      "aboutUs": "À propos",
      "conciergerie": "Conciergerie",
      "event": "Évènementiel",
      "collection": "Rejoindre la collection",
      "realEstate": "Acheter une maison",
      "travelPartners": "Agences partenaires"
    },
    "footer": {
      "callUs": "Nos conseillers sont joignables\nau",
      "contactUs": "Contactez-nous",
      "schedules": "du lun. au ven. de {startTimeWeek} à {endTimeWeek} et le sam. de {startTimeWeek} à {saturdayEndTimeWeek}."
    }
  },
  "en": {
    "menu": {
      "destinations": "Destinations",
      "inspirations": "Inspirations",
      "aboutUs": "About us",
      "conciergerie": "Conciergerie",
      "event": "Event",
      "collection": "Join our collection",
      "realEstate": "Buy a house",
      "travelPartners": "Travel partners"
    },
    "footer": {
      "callUs": "Our advisors can be reached\non",
      "contactUs": "Contact us",
      "schedules": "from Mon. to Fri. from {startTimeWeek} to {endTimeWeek} and Sat. from {startTimeWeek} to {saturdayEndTimeWeek}."
    }
  }
}
</i18n>
