<template>
  <div
    class="flex h-[46px] w-[514px] animate-pulse items-center justify-between rounded border border-gray-300 p-1"
  >
    <div class="flex grow items-center px-4">
      <div class="h-4 w-1/3 animate-pulse bg-gray-300" />
      <div class="mx-4 h-4 animate-pulse border-r border-gray-300" />
      <div class="h-4 w-1/3 animate-pulse bg-gray-300" />
      <div class="mx-4 h-4 animate-pulse border-r border-gray-300" />
      <div class="h-4 w-1/3 animate-pulse bg-gray-300" />
    </div>
    <div class="h-full w-[100px] flex-none animate-pulse rounded bg-gray-300" />
  </div>
</template>
