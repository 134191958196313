import {
  HousePeriodsResponseData,
  QueryModelType,
  ReplicasValues,
  FacetsRetrieve,
  SupportedCurrencies,
  SearchHouse,
  Mode,
  SearchParamsReturnType,
  Locale,
} from "../../../types";
import {
  GetSearchParamsType,
  GetSearchResponseType,
} from "../usecases/SearchHouses/types";
import { chunkArray, PRICES } from "./helpers";

import { SearchRepository } from "../usecases/SearchHouses/SearchHousesWithDates/interfaces/SearchHousesWithDatesRepository";

export class SearchQueries {
  protected searchHouses: SearchHouse[] = [];
  protected housesPeriods: HousePeriodsResponseData[] = [];
  protected noResultWithPeriod = false;

  protected async getSearchFacetsWithoutPeriod(input: {
    input: GetSearchParamsType;
    mode: Mode;
    getSearchParams: () => SearchParamsReturnType;
    locale?: Locale;
    repository: SearchRepository;
    algoliaFilters?: string;
  }) {
    const searchParams = input.getSearchParams();
    const areResults =
      input.input.housesPeriodIds?.validHousePeriodsIds.length ||
      input.input.housesPeriodIds?.validHousePeriodsIds.length;

    return areResults
      ? await this.getSearchResponse({
          ...searchParams,
          repository: input.repository,
          mode: input.mode,
          algoliaFilters: input.algoliaFilters,
        })
      : {};
  }

  protected async getSearchFacetsWithPeriod(input: {
    input: GetSearchParamsType;
    mode: Mode;
    getSearchParams: () => SearchParamsReturnType;
    locale?: Locale;
    repository: SearchRepository;
    algoliaFilters?: string;
  }) {
    const searchParams = input.getSearchParams();
    return await this.getSearchResponse({
      ...searchParams,
      repository: input.repository,
      mode: input.mode,
      algoliaFilters: input.algoliaFilters,
    });
  }

  private pricesAPIFormatter(
    minPrice: number,
    maxPrice: number,
    deviceMode: "web" | "mobile"
  ) {
    const {
      MULTIPLIER,
      MIN_PRICE_CHOICE,
      MAX_PRICE_CHOICE,
      MAX_PRICE_REQUEST,
    } = PRICES;

    if (deviceMode === "mobile") {
      if (minPrice === MIN_PRICE_CHOICE && maxPrice === MAX_PRICE_CHOICE) {
        return { minPrice: MIN_PRICE_CHOICE, maxPrice: MAX_PRICE_REQUEST };
      } else if (
        minPrice !== MIN_PRICE_CHOICE &&
        maxPrice >= MAX_PRICE_CHOICE
      ) {
        return {
          minPrice: Math.round(minPrice * MULTIPLIER),
          maxPrice: MAX_PRICE_REQUEST,
        };
      }
    }

    return {
      minPrice: Math.round(minPrice * MULTIPLIER),
      maxPrice: Math.round(maxPrice * MULTIPLIER),
    };
  }

  protected getNewHits(hits: SearchHouse[]) {
    return hits.map((hit) => {
      const housePeriod = this.housesPeriods.find((house) => {
        return house.id === String(hit.id);
      }) as HousePeriodsResponseData;

      const newHits = {
        ...hit,
        display_prices: !(!hit.display_prices || !housePeriod?.fullyCovered),
        publicPrice: housePeriod?.publicPrice,
        periodId: housePeriod?.id,
        allMinimumDurationValid: housePeriod?.allMinimumDurationValid,
        endAt: housePeriod?.endAt,
        fullyCovered: housePeriod?.fullyCovered,
        operationalCosts: housePeriod?.operationalCosts,
        startAt: housePeriod?.startAt,
        currency: housePeriod?.currency,
        commissionRate: housePeriod?.commissionRate,
        commissionRatePublicPrice: housePeriod?.commissionRatePublicPrice,
        definitivePrices: housePeriod?.definitivePrices,
        lcCommission: housePeriod?.lcCommission,
        ownerPriceWithoutOpCosts: housePeriod?.ownerPriceWithoutOpCosts,
        publicPriceWithoutOpCosts: housePeriod?.publicPriceWithoutOpCosts,
        currentSalesOptionDates: housePeriod?.currentSalesOptionDates,
        currentSalesOptionOwner: housePeriod?.currentSalesOptionOwner,
        currentHubspotContracts: housePeriod?.currentHubspotContracts,
      };

      return newHits;
    });
  }

  // API CALLS
  protected async getHouseIdsPeriodResponse({
    currency,
    destinationIds,
    deviceMode,
    housesIds,
    maxBudget,
    minBudget,
    period,
    repository,
    sortBy,
    states,
  }: {
    currency: SupportedCurrencies;
    destinationIds: string[];
    deviceMode: "web" | "mobile";
    maxBudget: number;
    minBudget: number;
    period: QueryModelType["period"];
    repository: SearchRepository;
    sortBy: ReplicasValues;
    housesIds: string[];
    states?: string;
  }) {
    const newDestinationIds =
      destinationIds.length > 0 ? { destinationIds } : {};
    const newHousesIds = housesIds.length > 0 ? { housesIds } : {};
    const newStates = states ? { states } : {};

    const { minPrice, maxPrice } = this.pricesAPIFormatter(
      minBudget,
      maxBudget,
      deviceMode
    );
    if (period) {
      const response = await repository.getHousePeriodsIds({
        currency,
        maxBudget: String(maxPrice),
        minBudget: String(minPrice),
        sortBy,
        ...period,
        ...newDestinationIds,
        ...newHousesIds,
        ...newStates,
      });

      return response;
    }
  }

  protected async getHousePeriodsResponse({
    currency,
    destinationIds,
    hits,
    maxBudget,
    minBudget,
    period,
    repository,
    sortBy,
    deviceMode,
  }: {
    deviceMode: "web" | "mobile";
    currency: SupportedCurrencies;
    destinationIds?: string[];
    hits: SearchHouse[];
    maxBudget: number;
    minBudget: number;
    period: QueryModelType["period"];
    repository: SearchRepository;
    sortBy: ReplicasValues;
  }) {
    if (hits.length && period) {
      const { minPrice, maxPrice } = this.pricesAPIFormatter(
        minBudget,
        maxBudget,
        deviceMode
      );
      const searchHouseIds = hits.map((hit) => String(hit.id));

      const chunks = chunkArray(searchHouseIds);

      const response = await Promise.all(
        chunks.map((ids) => {
          return repository.getHousePeriods({
            currency: currency,
            destinationIds,
            endDate: period.endDate,
            houseIds: ids,
            maxBudget: String(maxPrice),
            minBudget: String(minPrice),
            startDate: period.startDate,
            sortBy,
          });
        })
      ).then((res) => {
        if (res.length === 0) return [];

        return [...res.map((p) => p?.data || [])]?.flat();
      });

      this.housesPeriods = response;

      return this.getNewHits(hits);
    }

    return [];
  }

  private getModeFilters(
    mode: Mode,
    onlyHiddenHouses?: boolean,
    algoliaFilters?: string
  ) {
    const filters = algoliaFilters || "";
    const notHousesCount = "NOT houses_count=0";

    if (mode === "admin" || mode === "b2b2c") {
      return `NOT state:offboarded AND NOT state:on_hold AND NOT state:onboarding AND ${notHousesCount} ${filters}`;
    }

    if (onlyHiddenHouses) return `${notHousesCount} AND state:hidden`;

    return `${notHousesCount} AND NOT state:hidden`;
  }

  // ALGOLIA CALLS
  protected async getBrowseResponse({
    defaultFacets,
    facetFilters,
    housesIds,
    newQuery,
    numericFilters,
    prices,
    replica,
    repository,
    mode,
    onlyHiddenHouses,
    algoliaFilters,
  }: GetSearchResponseType) {
    const modeFilters = this.getModeFilters(
      mode,
      onlyHiddenHouses,
      algoliaFilters
    );
    const hits = await repository.algolia.browseQuery<SearchHouse>({
      query: newQuery,
      indexName: replica,
      params: {
        filters: `${modeFilters} ${housesIds} ${prices}`,
        facets: defaultFacets,
        facetFilters,
        numericFilters,
      },
    });

    return hits;
  }

  protected async getSearchResultByPage({
    algoliaFilters,
    defaultFacets,
    facetFilters,
    hitsPerPage,
    housesIds,
    mode,
    newQuery,
    numericFilters,
    onlyHiddenHouses,
    page,
    prices,
    replica,
    repository,
  }: GetSearchResponseType & {
    page: number;
    hitsPerPage?: number;
  }) {
    const modeFilters = this.getModeFilters(
      mode,
      onlyHiddenHouses,
      algoliaFilters
    );
    const { hits, nbHits, nbPages, facets, queryID } =
      await repository.algolia.simpleQuery<SearchHouse>({
        indexName: replica,
        query: newQuery,
        params: {
          facetFilters,
          facets: defaultFacets,
          filters: `${modeFilters} ${housesIds} ${prices}`,
          hitsPerPage,
          maxValuesPerFacet: 300,
          numericFilters,
          page,
        },
      });

    return { hits, nbHits, nbPages, facets, queryID };
  }

  protected async getSearchResponse({
    defaultFacets,
    facetFilters,
    housesIds,
    newQuery,
    numericFilters,
    prices,
    replica,
    repository,
    mode,
    onlyHiddenHouses,
    algoliaFilters,
  }: GetSearchResponseType) {
    const modeFilters = this.getModeFilters(
      mode,
      onlyHiddenHouses,
      algoliaFilters
    );
    const { facets } = await repository.algolia.simpleQuery<FacetsRetrieve>({
      indexName: replica,
      query: newQuery,
      params: {
        analytics: false,
        facetFilters,
        facets: defaultFacets,
        filters: `${modeFilters} ${housesIds} ${prices}`,
        hitsPerPage: 0,
        maxValuesPerFacet: 300,
        numericFilters,
      },
    });

    return facets;
  }
}
