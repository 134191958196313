<template>
  <div class="default-layout">
    <div class="default-layout__content">
      <slot />
    </div>
    <TheFooter :layout="String($route.meta?.footer ?? '')" />
  </div>
</template>

<style>
.default-layout {
  @apply min-h-screen flex flex-col;
}
</style>
