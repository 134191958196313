<script setup lang="ts">
import type { HouseSlug } from 'lc-services/types'

const { searchQuery = '', withImage = false } = defineProps<{
  searchQuery: string
  withImage?: boolean
}>()
const emits = defineEmits<{ 'option-clicked': [] }>()

const {
  destinations,
  houses,
  optionsSearch,
  optionsSearchEmpty,
  setData,
  PLACEHOLDER_DESTI,
} = useSearchInput()

const options = computed(() => {
  if (!searchQuery && !destinations.value.length && !houses.value.length) {
    return optionsSearchEmpty.value
  }

  return optionsSearch.value
})

const handleLocation = (
  id: string,
  name: string,
  type: 'destination' | 'house',
  slug?: HouseSlug,
) => {
  setData('location', {
    id,
    name,
    type,
    slug,
  })

  emits('option-clicked')
}
</script>

<template>
  <template v-if="options.length">
    <div
      v-for="d in options"
      :key="d.key"
      :class="['last:mb-0', withImage ? 'mb-9 ' : 'mb-2']"
    >
      <div
        :class="[
          'mb-2 text-left',
          { 'text-xs font-bold md:px-1': !withImage },
          { 'font-semibold': withImage },
        ]"
      >
        {{ d.title }}
      </div>
      <ul
        :class="[
          'mb-0 grid grid-cols-2',
          withImage
            ? 'gap-x-2 gap-y-4 md:grid-cols-4'
            : 'gap-x-4 laptop:grid-cols-1',
        ]"
      >
        <li
          v-for="item in d.items"
          :key="item.id"
          :class="[
            ' cursor-pointer text-left text-md',
            {
              'flex items-center py-2 hover:bg-gray-200 md:px-1': !withImage,
            },
          ]"
          @click.stop="handleLocation(item.id, item.name, d.key, item.slug)"
        >
          <div v-if="withImage" class="mb-2">
            <BaseNuxtImg
              :alt="item.name"
              :src="item.first_photo_url || PLACEHOLDER_DESTI"
              height="83"
              width="118"
              class="rounded-sm"
            />
          </div>
          <BaseIcon v-else :name="d.icon" class="mr-4 flex-none" />
          <span :class="['truncate', { 'block font-medium': withImage }]">
            {{ item.name }}
          </span>
        </li>
      </ul>
    </div>
  </template>
  <div v-else class="text-md md:px-4">
    {{ $t('field.placeholder.noResult') }}
  </div>
</template>
