<script setup lang="ts">
import { parsePhoneNumber, type CountryCode } from 'libphonenumber-js'
import { phoneByLanguage } from '~/helpers/getPhoneByLanguage'

const config = useRuntimeConfig()
const { user } = useUser()

const { color = 'primary', phone = null } = defineProps<{
  color?: 'primary' | 'primary-light' | 'black'
  phone?: string | null
}>()
defineEmits<{
  click: [MouseEvent]
}>()

const phoneNumber = computed(() =>
  typeof phone === 'string' && phone
    ? parsePhoneNumber(
        phone,
        (user.value.country as CountryCode) ?? undefined,
      ).formatInternational()
    : null,
)
const phoneNumberLC = computed(() =>
  typeof phone !== 'string'
    ? phoneByLanguage({
        config,
        language: user.value.country,
      })
    : null,
)
</script>

<template>
  <BaseButton
    v-if="phoneNumberLC"
    :color="color"
    :href="`tel:${phoneNumberLC}`"
    variant="link"
    @click="$emit('click', $event)"
  >
    {{ phoneNumberLC }}
  </BaseButton>
  <span v-else-if="phoneNumber">{{ phoneNumber }}</span>
</template>
