<script setup lang="ts">
withDefaults(
  defineProps<{
    tooltipPosition?: 'bottom-right'
  }>(),
  {
    tooltipPosition: 'bottom-right',
  },
)
defineSlots<{ default(): any }>()

const localePath = useLocalePath()
const { t } = useI18n()
const config = useRuntimeConfig()
const { trackEvent } = useTracking()
const { startTimeWeek, endTimeWeek, saturdayEndTimeWeek } =
  useCallCenterSchedule()

const dayUTC = new Date().getUTCDay()
const parisTime = new Date().toLocaleTimeString('fr-FR', {
  timeZone: 'Europe/Paris',
})
const [hour, minute] = parisTime.split(':')

const callsUntilMidnight = computed(
  () => config.public.callsUntilMidnight === 'true',
)

const paris = new Date()
paris.setUTCHours(Number(hour))
paris.setUTCMinutes(Number(minute))

const openWeekDay = new Date()
openWeekDay.setUTCHours(9)
openWeekDay.setUTCMinutes(30)

const closeWeekDay = new Date()
closeWeekDay.setUTCHours(callsUntilMidnight.value ? 23 : 18)
closeWeekDay.setUTCMinutes(callsUntilMidnight.value ? 59 : 30)

const openWeekendDay = new Date()
openWeekendDay.setUTCHours(9)
openWeekendDay.setUTCMinutes(30)

const closeWeekendDay = new Date()
closeWeekendDay.setUTCHours(18)
closeWeekendDay.setUTCMinutes(30)

const currentlyOpenWeekDay = computed(() => {
  return (
    ![0, 6].includes(dayUTC) &&
    paris.getTime() >= openWeekDay.getTime() &&
    paris.getTime() <= closeWeekDay.getTime()
  )
})

const currentlyOpenWeekendDay = computed(() => {
  return (
    [6].includes(dayUTC) &&
    paris.getTime() >= openWeekendDay.getTime() &&
    paris.getTime() <= closeWeekendDay.getTime()
  )
})

const isOpen = computed(() => {
  return currentlyOpenWeekDay.value || currentlyOpenWeekendDay.value
})

const handleTracking = () => {
  trackEvent({
    event: 'header_clicked',
    item: 'contact',
  })
}
</script>

<template>
  <BaseTooltip
    :position="tooltipPosition"
    class="header-phone"
    show
    variant="white"
  >
    <template #element>
      <slot />
    </template>

    <template #text>
      <div class="mb-4 flex items-center text-left">
        <div class="relative">
          <BaseIcon class="mr-4" name="phone" />
          <BaseIcon
            :class="[
              'absolute bottom-[-10px] right-[2px] mr-4',
              {
                'fill-secondary-500 stroke-white': isOpen,
                'fill-error stroke-white': !isOpen,
              },
            ]"
            :name="isOpen ? 'checkCircle' : 'deleteCircle'"
            :size="1"
          />
        </div>

        <div
          class="the-header-phone-schedule m-0 text-md text-gray-600"
          data-testid="the-header-phone-schedule"
        >
          <div>
            {{
              t('inquiry.contact_us', {
                startTimeWeek,
                endTimeWeek,
                saturdayEndTimeWeek,
              })
            }}
          </div>
          <span class="block font-bold uppercase">
            <template v-if="isOpen">
              <span class="text-secondary-500">{{
                $t('header.currentlyOpen')
              }}</span>
            </template>
            <template v-else>
              {{ $t('header.currentlyClosed') }}
            </template>
          </span>
        </div>
      </div>

      <div class="flex items-center text-left">
        <BaseIcon class="mr-4" name="mail" />
        <i18n-t
          keypath="header.contactHelp"
          tag="span"
          class="text-md text-gray-600"
          data-testid="the-header-phone-contact"
        >
          <template #link>
            <NuxtLink
              :to="localePath('contact')"
              class="font-bold text-primary-700 hover:text-primary-600"
              @click="handleTracking"
            >
              {{ $t('header.form') }}
            </NuxtLink>
          </template>
        </i18n-t>
      </div>
    </template>
  </BaseTooltip>
</template>

<style>
.header-phone.lc_tooltip .lc_tooltip-content {
  max-width: 34rem;
}

@media (min-width: 1280px) {
  .header-phone.lc_tooltip .lc_tooltip-content {
    min-width: 31rem;
  }
}

/* need to keep the tooltip open when hovering */
.header-phone:after {
  content: '';
  @apply absolute -bottom-3 left-0 w-full pb-3;
}
.the-header-phone-schedule p {
  margin: 0;
}
</style>
