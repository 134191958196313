import { v4 } from 'uuid'

declare global {
  // eslint-disable-next-line no-var
  var amplitude:
    | {
        getDeviceId: () => string
        setDeviceId: (id: string) => void
      }
    | undefined
}

export const useExperimentCookies = () => {
  const AMP_DEVICE_ID_COOKIE = '_amp'
  const EXPERIMENT_DEBUG_COOKIE = 'experiment_debug'

  const {
    public: { appDomain },
  } = useRuntimeConfig()
  const { $httpCookies } = useNuxtApp()
  const { authUserIdTracking } = useAuth()

  // Debug Cookie
  const isDebugging = useCookie(EXPERIMENT_DEBUG_COOKIE, {
    default: () => {
      const existing = $httpCookies.get(EXPERIMENT_DEBUG_COOKIE)
      if (existing) return existing
      const value = ['development', 'staging', 'reviews'].includes(appDomain)
      $httpCookies.set(EXPERIMENT_DEBUG_COOKIE, value)
      return value
    },
  })
  // Device ID Cookie
  const amplitudeDeviceId = useCookie<string>(AMP_DEVICE_ID_COOKIE, {
    default: () => {
      const value = $httpCookies.get(AMP_DEVICE_ID_COOKIE)
      if (value) return value
      const randomUUID = v4()
      $httpCookies.set(AMP_DEVICE_ID_COOKIE, randomUUID)
      return randomUUID
    },
  })

  const experimentUser = computed(() => ({
    device_id: amplitudeDeviceId.value,
    ...(authUserIdTracking.value ? { user_id: authUserIdTracking.value } : {}),
  }))

  /**
   * Sets the Device ID sent by the Amplitude SDK.
   * This is needed because:
   * - we are setting a `device_id` ourselves in the code above
   * - we use a [plugin to load the Amplitude SDK](../../plugins/06.gtm.client.ts)
   * - we use a [plugin to load the Experiment SDK](../../plugins/10.experiment.ts)
   */
  const patchAmplitudeSDK = () => {
    if (!import.meta.client || import.meta.dev) return

    const observer = new MutationObserver((_, obs) => {
      if (
        'amplitude' in globalThis &&
        typeof globalThis.amplitude?.getDeviceId === 'function' &&
        typeof globalThis.amplitude?.setDeviceId === 'function'
      ) {
        if (globalThis.amplitude.getDeviceId() !== amplitudeDeviceId.value)
          globalThis.amplitude.setDeviceId(amplitudeDeviceId.value)
        obs.disconnect()
      }
    })
    observer.observe(document.head, { childList: true })
    return observer
  }

  return {
    amplitudeDeviceId,
    experimentUser,
    isDebugging,
    patchAmplitudeSDK,
  }
}
