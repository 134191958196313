<script setup lang="ts">
const { $lcRepositories } = useNuxtApp()
const { t } = useI18n({ useScope: 'local' })
const { trackEvent } = useTracking()
const { user } = useUser()

const success = ref(false)
const error = ref(false)
const email = ref(user.value.email)
const onSubmit = async () => {
  if (email.value) {
    const { errors } =
      await $lcRepositories.newsletterEmails.subscribeNewsletter({
        email: email.value,
      })
    error.value = Boolean(errors?.response?.data?.errors?.length)
    if (!error.value) {
      email.value = ''
      success.value = true
      trackEvent({
        event: 'newsletter_subscription',
        label: 'footer',
        post_alert: false,
      })
    }
  }
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <h2 class="m-0 text-xl">
      {{ t('title') }}
    </h2>

    <p v-if="success" class="m-0 text-md">
      {{ t('success') }}
    </p>

    <template v-else>
      <div class="flex w-full items-center">
        <BaseFormComponentsBaseInput
          v-model="email"
          name="email"
          type="email"
          wrapper-class="grow m-0"
          :disabled="Boolean(user.email)"
          :placeholder="t('placeholder')"
        />
        <BaseButton
          class="flex justify-end rounded-l-none"
          color="secondary"
          type="button"
          @click="onSubmit"
        >
          {{ t('cta') }}
        </BaseButton>
      </div>

      <p v-if="error" class="m-0 text-error">{{ t('error') }}</p>
      <p v-else class="m-0 text-md text-gray-600">
        {{ t('text') }}
      </p>
    </template>
  </div>
</template>

<i18n lang="yaml">
fr:
  title: Rejoignez la LC Family !
  placeholder: Entrez votre e-mail
  cta: S'inscrire
  text: 'Abonnez-vous à notre newsletter : découvrez nos inspirations et guides de voyage, notre sélection des plus belles maisons du monde et nos offres exclusives.'
  success: Votre inscription a bien été prise en compte !
  error: L'email est invalide
en:
  title: Join the LC Family!
  placeholder: Enter your email
  cta: Subscribe
  text: Subscribe to our newsletter to discover our travel inspirations. We have guides, our selection of the world's most beautiful homes and our exclusive offers.
  success: You have been successfully subscribed!
  error: Email is invalid
</i18n>

<style scoped>
:deep(.lc-btn) {
  @apply rounded-l-none;
}
</style>
