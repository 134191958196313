<script setup lang="ts">
const { contactOpen, setContactOpen } = useHeaderV2()
</script>

<template>
  <BaseModalV2
    :model-value="contactOpen"
    @update:model-value="setContactOpen($event)"
  >
    <template #content>
      <ContactForm @finished="setContactOpen(false)">
        <template #title>
          <p class="font-monospace mb-6 text-4xl leading-8">
            {{ $t('contact.title') }}
          </p>
          <p class="mb-6">
            {{ $t('contact.description') }}
          </p>
        </template>
      </ContactForm>
    </template>
  </BaseModalV2>
</template>
