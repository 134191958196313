<script setup lang="ts">
import { getDestinationSlug } from '~/helpers/getDestinationSlug'

import type { HouseSlug, Locale } from 'lc-services/types'

const { locale } = useI18n<unknown, Locale>()

const { headerNavigation } = useHeaderNavigation()
const { setMenuOpen } = useHeaderV2()

const inspirationsLinks = computed(
  () => headerNavigation.value.inspirationsLinks,
)

const magazineLink = computed(
  () => `https://magazine.lecollectionist.com/${locale.value}`,
)

const getPathDestination = (destinationNames: HouseSlug) =>
  getDestinationSlug(destinationNames)[locale.value].path
</script>

<template>
  <div class="flex h-full flex-col justify-between overflow-y-auto px-6">
    <div>
      <div class="font-monospace mb-7 text-xl">Inspirations</div>
      <ul>
        <li v-for="link in inspirationsLinks" :key="link.id">
          <NuxtLink
            class="relative inline-block py-1.5 text-md leading-6 text-gray-700 after:absolute after:bottom-1.5 after:left-0 after:h-px after:w-full after:origin-bottom-left after:scale-x-0 after:bg-secondary-400 after:opacity-0 after:transition-all after:duration-500 after:ease-out after:content-[''] hover:text-secondary-400 hover:no-underline hover:after:scale-x-100 hover:after:opacity-100"
            :to="getPathDestination(link.href)"
            @click.prevent="setMenuOpen(false)"
          >
            {{ locale === 'fr' ? link.titleFr : link.titleEn }}
          </NuxtLink>
        </li>
      </ul>
    </div>
    <div class="mb-8">
      <div class="mx-auto flex w-[129px] justify-center">
        <BaseNuxtImg
          src="production/assets/images/inspirations/more-inspiration.png"
          alt="inspirations"
          class="mb-5"
          width="129"
          height="104"
        />
      </div>
      <p class="mb-0 text-md font-semibold">
        {{ $t('headerInspirations.moreInspirations') }}
      </p>
      <p class="mb-3.5 text-xs">
        {{ $t('headerInspirations.joinMagazine') }}
      </p>
      <BaseButton
        :href="magazineLink"
        class="!text-xs underline hover:!underline"
        color="secondary"
        target="_blank"
        variant="link"
      >
        {{ $t('headerInspirations.exploreTheMagazine') }}
      </BaseButton>
    </div>
  </div>
</template>
