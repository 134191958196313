<script setup lang="ts">
import type BaseCalendar from '@/components/BaseForm/components/BaseCalendar.vue'

const { large = false, hideInput = false } = defineProps<{
  hideInput?: boolean
  large?: boolean
}>()
const emits = defineEmits<{ selected: []; clicked: [] }>()

const { isMobile } = useBreakpoint()
const { data, setData } = useSearchInput()
const { favoritePeriod, isFavoritePeriodWeekly } = useSearchFavoritePeriod()

const searchCalendarRef = ref<InstanceType<typeof BaseCalendar> | null>(null)

const handleEndDate = (date: Date) => {
  setData('endDate', date)

  emits('selected')
}

const openCalendar = () => {
  setTimeout(() => {
    searchCalendarRef.value?.openCalendar()
  }, 100)
}

defineExpose({
  openCalendar,
})
</script>

<template>
  <LazyBaseFormComponentsBaseCalendar
    ref="searchCalendarRef"
    :check-in="data.startDate"
    :check-out="data.endDate"
    :has-header="isMobile"
    :is-affixed="isMobile"
    :show-input-calendar="!hideInput"
    :placeholder="{
      checkIn: $t('calendar.check_in'),
      checkOut: $t('calendar.check_out'),
    }"
    :class="['input-search-v2', { 'input-search-v2-large': large }]"
    disabled-days-before-day-date
    has-footer
    name="searchCalendar"
    wrapper-class=""
    @open-calendar="$emit('clicked')"
    @update:end-date="handleEndDate"
    @update:start-date="($event) => setData('startDate', $event)"
  >
    <template #lc-calendar-header-mobile="{ clearDates, closeDatePicker }">
      <div class="mb-4 flex justify-between text-gray-700">
        <button
          class="flex items-center"
          type="button"
          @click="closeDatePicker"
        >
          <BaseIcon class="mr-2" name="navArrowLeft" />
          <div class="text-md font-medium">
            {{ $t('action.back') }}
          </div>
        </button>
        <button
          :class="[
            'text-md font-bold underline',
            { 'text-gray-400': !data.startDate && !data.endDate },
            { 'text-gray-700': data.startDate || data.endDate },
          ]"
          type="button"
          @click="clearDates"
        >
          {{ $t('inquiry.clearDates') }}
        </button>
      </div>
      <DestinationFavoritePeriod
        v-if="isFavoritePeriodWeekly"
        class="fixed inset-x-4 bottom-4 z-fixed text-xs"
        v-bind="favoritePeriod"
      />
    </template>
    <template #lc-calendar-footer="{ clearDates }">
      <div
        :class="[
          'flex items-center gap-4 text-md',
          { 'justify-between': isFavoritePeriodWeekly },
          { 'justify-end': !isFavoritePeriodWeekly },
        ]"
      >
        <DestinationFavoritePeriod
          v-if="isFavoritePeriodWeekly"
          v-bind="favoritePeriod"
        />

        <button
          :class="[
            'flex-none text-md font-bold underline',
            {
              'cursor-not-allowed text-gray-400':
                !data.startDate && !data.endDate,
            },
            { 'text-gray-700': data.startDate || data.endDate },
          ]"
          :disabled="!data.startDate && !data.endDate"
          type="button"
          @click="clearDates"
        >
          {{ $t('inquiry.clearDates') }}
        </button>
      </div>
    </template>
  </LazyBaseFormComponentsBaseCalendar>
</template>

<style>
.input-search-v2 .calendar_input {
  @apply border-0 h-[56px];
}

.input-search-v2 .calendar_input-left-part + svg {
  @apply !size-6	text-gray-700 mr-[-4px] stroke-[0.99];
}

.input-search-v2 .calendar_input-text {
  @apply w-max;
}

.input-search-v2 .calendar_input-text,
.input-search-v2 .calendar_input-text--hasnt-checkIn,
.input-search-v2 .calendar_input-calendar--hasnt-checkIn,
.input-search-v2 .calendar_input-arrowRight--hasnt-checkIn {
  @apply text-gray-500 text-md;
}

.input-search-v2-large .calendar_input-text,
.input-search-v2-large .calendar_input-text--hasnt-checkIn,
.input-search-v2-large .calendar_input-calendar--hasnt-checkIn,
.input-search-v2-large .calendar_input-arrowRight--hasnt-checkIn {
  @apply text-base;
}

.input-search-v2 .calendar_input-arrowRight {
  @apply mx-3;
}

@media (min-width: 768px) {
  .input-search-v2 {
    @apply static;
  }

  .input-search-v2 .calendar_wrapper {
    @apply rounded;
  }

  .input-search-v2 .calendar_input {
    @apply h-[36px] p-0;
  }

  .input-search-v2 .calendar_wrapper.calendar_wrapper--left {
    @apply top-[54px] left-1/2 -translate-x-1/2;
  }
  .input-search-v2.input-search-v2-large
    .calendar_wrapper.calendar_wrapper--left {
    @apply top-[60px];
  }

  .input-search-v2 .calendar_input-left-part + svg {
    @apply !size-[1.1rem] ml-2 stroke-[1.5px];
  }

  .input-search-v2 .calendar_input-calendar {
    @apply hidden;
  }
}
</style>
