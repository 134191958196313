<script setup lang="ts">
const { signInChoiceOpen, setSignInChoiceOpen } = useHeaderV2()
const localePath = useLocalePath()
const { trackEvent } = useTracking()

const handleTrack = (connectionType: string) => {
  setSignInChoiceOpen(false)

  trackEvent({
    event: 'header_connection_click',
    connection_type: connectionType,
  })
}
</script>

<template>
  <BaseModalV2
    :model-value="signInChoiceOpen"
    @update:model-value="setSignInChoiceOpen($event)"
  >
    <template #content>
      <div>
        <div class="font-monospace mb-9 text-xl">{{ $t('auth.signin') }}</div>
        <NuxtLink
          class="block border-b border-gray-200 py-6 text-gray-700"
          :to="localePath('login')"
          @click.prevent="handleTrack('Client or owner')"
        >
          {{ $t('header.customerOrOwner') }}
        </NuxtLink>
        <NuxtLink
          class="block py-6 text-gray-700"
          :to="localePath('login')"
          @click.prevent="handleTrack('Travel agent')"
        >
          {{ $t('header.travelAgent') }}
        </NuxtLink>
      </div>
    </template>
  </BaseModalV2>
</template>
