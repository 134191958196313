<script setup lang="ts">
import { phoneByLanguage } from '~/helpers/getPhoneByLanguage'
import type { VueMessageType } from '#i18n'
import type { MobileStore } from '~/types/types'

// Statics
const VALUES_ICON: Readonly<Record<string, IconPath>> = {
  hospitality: 'hote',
  availability: 'shellFish',
  confidentiality: 'livingRoom',
  offer: 'discoBall',
}
const CONTACT_ICONS: Readonly<Record<string, IconPath>> = {
  phone: 'phone',
  mail: 'mail',
  meeting: 'multiBubble',
  faq: 'chatBubbleQuestion',
  career: 'group',
}

const { layout = '' } = defineProps<{
  layout?: 'none' | 'compact' | 'extended' | 'full' | string
}>()

const config = useRuntimeConfig()
const { locale, t, tm, rt } = useI18n()
const { isMobile } = useBreakpoint()
const { trackEvent } = useTracking()
const { user } = useUser()
useMeetingScheduler()

const footerRef = ref<HTMLDivElement | null>(null)
const modalMobileApp = ref(false)
const footerIsInViewport = useState('footer-in-viewport', () => false)

const sections = computed(() => ({
  values: !layout || layout === 'full',
  trustees: !layout || ['extended', 'full'].includes(layout),
  links: !layout || ['extended', 'full', 'compact'].includes(layout),
}))
const phoneNumber = computed(() =>
  phoneByLanguage({
    config,
    language: user.value.country,
  }),
)
const getFullYear = computed(() => new Date().getFullYear())
const values = computed(() =>
  Object.entries(VALUES_ICON).map(([key, icon]) => ({
    icon,
    key,
    text: t(`values.${key}.text`),
    title: t(`values.${key}.title`),
  })),
)
const links = computed(() =>
  ['destinations', 'services', 'owners']
    .map((key) => ({
      key,
      title: t(`links.${key}.label`),
      links: (
        tm(`links.${key}.items`) as {
          href: VueMessageType
          label: VueMessageType
        }[]
      ).map((link) => ({
        href: rt(link.href),
        label: rt(link.label),
      })),
    }))
    .concat([
      {
        key: 'contact',
        title: t('links.contact.label'),
        links: Object.entries(CONTACT_ICONS).map(([key, icon]) => ({
          href: t(`links.contact.${key}.href`, { tel: phoneNumber.value }),
          label: t(`links.contact.${key}.label`),
          icon: key === 'phone' ? null : icon,
        })),
      },
    ]),
)

const trackClick = (whichDevice: MobileStore) => {
  trackEvent({
    app_device: whichDevice,
    event: 'touchpoint_store',
    source: 'footer',
  })
}

const provider = {
  '@type': 'Organization',
  name: 'Le Collectionist',
  url: 'https://www.lecollectionist.com',
  logo: 'https://cdn.lecollectionist.com/__collectionist__/production/assets/images/lecollectionist-luxury-villas-rentals-holidays.svg',
  sameAs: [
    'https://www.facebook.com/lecollectionist',
    'https://www.instagram.com/lecollectionist',
    'https://www.linkedin.com/company/le-collectionist',
  ],
}
const availableChannel = {
  '@type': 'ContactPoint',
  areaServed: locale.value.toUpperCase(),
  availableLanguage: ['French', 'English'],
  contactType: 'customer support',
  email: 'contact+site@lecollectionist.com',
  telephone: phoneNumber.value,
  url: new URL(`/${locale.value}/contact`, config.public.baseUrl).toString(),
}
useHead(
  {
    script: [
      {
        innerHTML: JSON.stringify({
          '@context': 'https://schema.org',
          '@graph': [
            availableChannel,
            provider,
            ...links.value[1].links.map(({ label: name }) => ({
              '@type': 'Service',
              areaServed: {
                '@type': 'Place',
                name: 'France',
              },
              availableChannel,
              name,
              provider,
            })),
          ],
        }),
        type: 'application/ld+json',
      },
    ],
  },
  { mode: 'server' },
)

const onScroll = () => {
  if (import.meta.client && footerRef.value) {
    const { top, height } = footerRef.value.getBoundingClientRect()
    footerIsInViewport.value = top < height
  }
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})
onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<template>
  <footer
    v-if="layout !== 'none'"
    ref="footerRef"
    class="m-0 flex w-full flex-col border-t border-t-gray-200 pb-10 xl:items-center"
  >
    <!-- Values -->
    <div
      v-if="sections.values"
      class="w-full border-b border-b-gray-200 px-4 py-10 sm:px-12 sm:py-16"
    >
      <section
        class="flex flex-col gap-8 sm:flex-row sm:flex-wrap sm:gap-x-0 sm:gap-y-6 xl:mx-auto xl:max-w-screen-2xl"
      >
        <div
          v-for="value in values"
          :key="value.key"
          class="flex w-full gap-4 sm:w-1/2 sm:flex-col sm:items-center sm:text-center xl:w-1/4"
        >
          <div class="w-10 sm:w-20">
            <BaseIcon
              v-if="value.icon !== 'hote'"
              :name="value.icon"
              :size="isMobile ? 2.5 : 4"
            />
            <img v-else alt="hospitality" src="~/assets/images/hote.svg" />
          </div>
          <div class="grow">
            <h2 class="text-xl">{{ value.title }}</h2>
            <p class="text-xs text-gray-600 sm:px-6 sm:text-sm">
              {{ value.text }}
            </p>
          </div>
        </div>
      </section>
    </div>

    <!-- Trustees + Mobile app -->
    <div
      v-if="sections.trustees"
      class="w-full border-b border-b-gray-200 px-4 pt-10 sm:px-0 sm:pt-16"
    >
      <section
        class="flex w-full flex-col gap-8 sm:gap-12 sm:px-12 xl:mx-auto xl:max-w-screen-2xl xl:flex-row-reverse"
      >
        <div
          class="flex w-full flex-col border-b border-gray-200 pb-10 sm:gap-4 sm:pb-16 xl:mb-16 xl:w-1/2 xl:border-b-0 xl:border-l xl:pl-12"
        >
          <h2 class="mb-2 text-xl sm:m-0">{{ t('trustees.title') }}</h2>
          <p class="mb-6 text-md text-gray-600 sm:mb-2">
            {{ t('trustees.text') }}
          </p>
          <div class="flex items-center gap-4">
            <img
              alt="Condénast Traveler readers’ choice awards"
              class="h-[72px]"
              src="~/assets/images/condenast/condenast_2024_2023_2022.svg"
            />
            <img
              alt="B-Corporation"
              class="h-[72px] px-8"
              src="~/assets/images/b-corp.svg"
            />
          </div>
        </div>

        <div class="flex w-full gap-8 sm:h-60 lg:h-80 xl:w-1/2">
          <div
            class="hidden items-end sm:flex sm:h-full sm:w-1/3 lg:w-1/4 xl:w-1/2"
          >
            <BaseNuxtImg
              :alt="t('mobile.title')"
              class="object-top"
              sizes="sm:264px lg:528px"
              :src="`production/assets/images/footer/mobile-app-iphone16-pro-${locale}.png`"
            />
          </div>
          <div class="flex grow flex-col pb-12 sm:gap-4">
            <h2 class="mb-2 text-xl sm:m-0">{{ t('mobile.title') }}</h2>
            <p class="mb-6 text-md text-gray-600 sm:mb-2">
              {{ t('mobile.text') }}
            </p>
            <div class="flex h-8 items-center gap-4 lg:mt-8">
              <NuxtLink
                rel="nofollow noopener"
                target="_blank"
                :to="config.public.ownerAppIOS"
                external
              >
                <img
                  alt="logo-apple-store"
                  class="w-28"
                  :src="`/stores/app-store-${locale}.svg`"
                  @click="trackClick('app-store')"
                />
              </NuxtLink>
              <NuxtLink
                rel="nofollow noopener"
                target="_blank"
                :to="config.public.ownerAppAndroid"
                external
              >
                <img
                  alt="logo-play-store"
                  class="w-28"
                  :src="`/stores/play-store-${locale}.svg`"
                  @click="trackClick('google-play')"
                />
              </NuxtLink>
            </div>
            <BaseButton
              v-if="!isMobile"
              class="pt-3 text-xs !font-normal sm:mt-3 sm:inline xl:mt-0"
              color="grey"
              variant="link"
              @click="modalMobileApp = true"
            >
              {{ t('mobile.cta') }}
            </BaseButton>
          </div>
        </div>
      </section>
    </div>

    <!-- Newsletter + Links -->
    <div
      v-if="sections.links"
      class="flex w-full flex-wrap justify-between gap-8 px-4 py-10 sm:gap-16 sm:px-12 sm:py-16 xl:max-w-screen-2xl"
    >
      <TheFooterSubscribeToNewsletter class="w-full xl:w-1/3" />
      <div class="flex grow flex-col sm:flex-row xl:pl-2">
        <TheFooterGroupLinks
          v-for="link in links"
          :key="link.key"
          :links="link.links"
          :title="link.title"
        />
      </div>
    </div>

    <!-- Legal & Social -->
    <div
      class="flex w-full flex-col gap-8 px-4 pt-10 sm:flex-row-reverse sm:justify-between sm:px-12 xl:mx-auto xl:max-w-screen-2xl"
    >
      <div
        class="flex w-full items-start justify-between sm:w-1/3 sm:gap-x-6 xl:w-1/6"
      >
        <BaseNavigationSwitch
          class="!mx-0 min-w-fit sm:items-center"
          :position="`bottom-8 ${isMobile ? 'left-0' : 'right-0'}`"
        />
        <TheFooterSocialNetwork class="justify-end" />
      </div>

      <div class="flex grow flex-wrap items-center gap-x-4 gap-y-1 text-2xs">
        <p class="mb-0 hidden text-gray-500 lg:block">
          {{ getFullYear }} {{ $t('footer.all_right_reserved') }}
        </p>
        <TheFooterSimpleLink
          v-for="key in ['privacy', 'cookies', 'terms', 'sales', 'sitemap']"
          :key="key"
          class="text-gray-500"
          :href="t(`legal.${key}.href`)"
        >
          {{ t(`legal.${key}.label`) }}
        </TheFooterSimpleLink>
        <p class="mb-0 text-gray-500 sm:hidden">
          {{ getFullYear }} {{ $t('footer.all_right_reserved') }}
        </p>
      </div>
    </div>

    <BaseModalV2 v-model="modalMobileApp">
      <template #content>
        <div class="flex w-full items-center justify-center">
          <div class="flex h-fit w-1/2 justify-center px-10">
            <BaseNuxtImg
              sizes="192px"
              :alt="t('mobile.modal.title')"
              :src="`production/assets/images/footer/mobile-app-onboarding-${locale}.png`"
            />
          </div>
          <div class="flex h-full w-1/2 flex-col items-center gap-6">
            <img
              :alt="t('mobile.modal.title')"
              class="rounded-lg"
              height="168"
              src="~/assets/svg/QRCode.svg"
              width="168"
            />

            <div>
              <h2 class="mb-2 text-center text-xl">
                {{ t('mobile.modal.title') }}
              </h2>
              <p class="text-center text-md">
                {{ t('mobile.modal.text[0]') }}
                <br />
                {{ t('mobile.modal.text[1]') }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </BaseModalV2>
  </footer>
</template>

<style scoped>
:deep(.lc-collapse-icon) {
  @apply !w-6 !h-6;
}
</style>

<i18n lang="yaml">
fr:
  values:
    hospitality:
      title: À l'écoute & proactifs
      text: Nous privilégions l'échange et une relation de confiance.
    availability:
      title: Sur le terrain
      text: Nos équipes connaissent tous les secrets de nos destinations.
    confidentiality:
      title: Rien que le meilleur
      text: Nous sélectionnons les maisons et expériences les plus spectaculaires à travers le monde.
    offer:
      title: À votre (dé)mesure
      text: Grâce à notre conciergerie experte, nous créons les vacances qui vous ressemblent.
  trustees:
    title: Certifié et récompensé pour son excellence
    text: Depuis 2014, nous avons plusieurs fois été récompensés par Condé Nast Traveler. En 2024, nous avons eu le privilège d’obtenir le label B Corp pour une entreprise plus responsable.
  mobile:
    title: Téléchargez l'app dès maintenant
    text: Personnalisez chaque détail de votre séjour, gérez vos réservations et accédez à notre conciergerie simplement via l’application.
    cta: Envoyer sur mon mobile
    modal:
      title: Téléchargez l'application
      text:
        - Découvrez des propriétés d'exception,
        - réservables en quelques clics.
  links:
    destinations:
      label: Destinations
      items:
        - label: Ibiza
          href: location-villas-luxe/ibiza
        - label: Cap Ferret
          href: location-villas-luxe/cap-ferret
        - label: Provence
          href: location-villas-luxe/provence
        - label: Corse
          href: location-villas-luxe/corse
        - label: Grèce
          href: location-villas-luxe/grece
        - label: Italie
          href: location-villas-luxe/italie
        - label: Voir plus
          href: recherche
    services:
      label: Services
      items:
        - label: Louez avec nous
          href: vacances-sur-mesure
        - label: Notre magazine
          href: https://magazine.lecollectionist.com/fr
        - label: La conciergerie
          href: notre-conciergerie-de-luxe
        - label: Corporate & Private Events
          href: service-evenementiel
        - label: Agences partenaires
          href: agences-partenaires
        - label: Achetez une maison
          href: immobilier-luxe-annonces
    owners:
      label: Propriétaires
      items:
        - label: Rejoignez-nous
          href: proprietaires
        - label: Témoignages
          href: https://magazine.lecollectionist.com/fr/tag/entretiens-proprietaires
    contact:
      label: Contactez-nous
      phone:
        label: Appelez le
        href: 'tel:{tel}'
      mail:
        label: Contactez-nous
        href: contact
      meeting:
        label: Prendre rendez-vous
        href: javascript:openMeetingScheduler()
      faq:
        label: FAQ
        href: http://help.lecollectionist.com/l/fr
      career:
        label: Carrière
        href: https://careers.flatchr.io/company/lecollectionist/
  legal:
    privacy:
      label: Politique de confidentialité
      href: politique-confidentialite
    cookies:
      label: Conditions des cookies
      href: javascript:openAxeptioCookies()
    terms:
      label: Conditions d'utilisation
      href: conditions-generales-utilisation
    sales:
      label: CGV
      href: conditions-client
    sitemap:
      label: Plan du site
      href: plan-du-site
  copyright: 2024 Tous droits réservés
en:
  values:
    hospitality:
      title: Attentive and proactive
      text: We place emphasis on a relationship based on exchange and trust.
    availability:
      title: On the ground
      text: Our local teams know all the secrets of our destinations.
    confidentiality:
      title: Nothing but the best
      text: We only select the most spectacular homes and experiences around the world.
    offer:
      title: Tailor made
      text: Thanks to our expert concierge service, we can create the holiday that's perfect for you.
  trustees:
    title: Certified and awarded for excellence
    text: Since 2014, we have received several awards from Condé Nast Traveler. In 2024, we were privileged to be awarded the B Corp label as a more responsible company.
  mobile:
    title: Download the app now
    text: Personalise every detail of your stay, manage your bookings, and access our concierge service easily through the app.
    cta: Send to my mobile
    modal:
      title: Download the app
      text:
        - Discover exceptional properties,
        - bookable in just a few clicks.
  links:
    destinations:
      label: Destinations
      items:
        - label: Ibiza
          href: luxury-villas-rentals/ibiza
        - label: Cap Ferret
          href: luxury-villas-rentals/cap-ferret
        - label: Provence
          href: luxury-villas-rentals/provence
        - label: Corsica
          href: luxury-villas-rentals/corsica
        - label: Greece
          href: luxury-villas-rentals/greece
        - label: Italy
          href: luxury-villas-rentals/italy
        - label: View all
          href: search
    services:
      label: Services
      items:
        - label: Rent with us
          href: custom-holidays
        - label: Our magazine
          href: https://magazine.lecollectionist.com/en
        - label: La conciergerie
          href: our-luxury-concierge-service
        - label: Corporate & Private Events
          href: event-service
        - label: Travel partners
          href: travel-partners
        - label: Buy a house
          href: luxury-real-estate-listing
    owners:
      label: Owners
      items:
        - label: Become a host
          href: owners
        - label: Testimonials
          href: https://magazine.lecollectionist.com/en/tag/owner-interviews
    contact:
      label: Contact us
      phone:
        label: Call {tel}
        href: 'tel:{tel}'
      mail:
        label: Send a message
        href: contact
      meeting:
        label: Schedule a call
        href: javascript:openMeetingScheduler();
      faq:
        label: FAQ
        href: http://help.lecollectionist.com/l/en
      career:
        label: Careers
        href: https://careers.flatchr.io/en/company/lecollectionist/
  legal:
    privacy:
      label: Privacy policy
      href: privacy-policy
    cookies:
      label: Manage cookies
      href: javascript:openAxeptioCookies();
    terms:
      label: Terms of use
      href: terms-of-use
    sales:
      label: T&C's
      href: client-conditions
    sitemap:
      label: Sitemap
      href: sitemap
  copyright: 2024 All rights reserved
</i18n>
